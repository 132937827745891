exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".block--37XiJ {\n  background-color: #142444;\n  color: #fff;\n  padding: 22px;\n}\n@media (min-width: 960px) {\n  .block--37XiJ {\n    padding: 35px 30px 45px;\n  }\n}\n@media (min-width: 1280px) {\n  .block--37XiJ {\n    padding: 40px 40px 50px;\n  }\n}\n@media (min-width: 1440px) {\n  .block--37XiJ {\n    padding: 75px 55px 80px;\n  }\n}\n.block--37XiJ + .block--37XiJ {\n  margin: 20px 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"block": "block--37XiJ"
};