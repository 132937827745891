import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Intro.less';

import {IconQuote, IconArrowRight}     from 'Icons';
import {Link, Image}                   from 'Elements';
import {Footer, Authentication, Aside} from 'Components';

import Navigation from './Navigation';
import Helmet     from 'react-helmet';

import { getViewPortSize } from '../../utils/libs/getViewPortSize.js';

import { PHONE } from '../../utils/constants';

import IconLogo     from './__static__/IconLogo.svg';
import IconChat     from './__static__/IconChat.svg';
import IconKey      from './__static__/IconKey.svg';
import IconTriangle from './__static__/IconTriangle.svg';
import IconMore     from './__static__/IconMore.svg';
import IconLogo2    from './__static__/IconLogo2.svg';

import imagePhone     from './__static__/imagePhone.png';
import imageRadar     from './__static__/imageRadar.png';
import imageNeighbors from './__static__/imageNeighbors.jpg';
import imageHouse     from './__static__/imageHouse.jpg';
import imageDistrict  from './__static__/imageDistrict.jpg';
import imageService   from './__static__/imageService.png';
import imageText      from './__static__/imageText.png';
import imageFounder   from './__static__/imageFounder.jpg';

let xDown = null;
let yDown = null;

const ANIMATION_TIME = 1200;

function getTouches(evt) {
  return evt.touches || evt.originalEvent.touches;
}

class Intro extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      slide: 1,
      animation: false
    };

    this.intro = React.createRef();
    this.slider = React.createRef();
    this.slide1 = React.createRef();
    this.slide2 = React.createRef();
    this.slide3 = React.createRef();
    this.slide4 = React.createRef();
    this.slide5 = React.createRef();
    this.slide6 = React.createRef();

    this.setSlide = this.setSlide.bind(this);
    this.onScrollHandler = this.onScrollHandler.bind(this);
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this);
    this.touchStartHandler = this.touchStartHandler.bind(this);
    this.touchMoveHandler = this.touchMoveHandler.bind(this);
  }

  componentDidMount() {
    this.intro.current.addEventListener('scroll', this.onScrollHandler);
    this.slider.current.addEventListener('scroll', this.onScrollHandler);
    this.slider.current.addEventListener('wheel', this.onScrollHandler);
    this.slider.current.addEventListener('touchstart', this.touchStartHandler, false);
    this.slider.current.addEventListener('touchmove', this.touchMoveHandler, false);
    document.addEventListener('keyup', this.onKeyUpHandler);
  }

  componentWillUnmount() {
    this.intro.current.removeEventListener('scroll', this.onScrollHandler);
    this.slider.current.removeEventListener('scroll', this.onScrollHandler);
    this.slider.current.removeEventListener('wheel', this.onScrollHandler);
    this.slider.current.removeEventListener('touchstart', this.touchStartHandler);
    this.slider.current.removeEventListener('touchmove', this.touchMoveHandler);
    document.removeEventListener('keyup', this.onKeyUpHandler);
  }

  componentDidUpdate() {
    if (this.state.animation) {
      setTimeout(() => {
        this.setState({
          animation: false
        })
      }, ANIMATION_TIME)
    }
  }

  touchStartHandler(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  touchMoveHandler(evt) {
    const {width, height} = getViewPortSize();

    if (this.state.animation || width < 960) {
      return;
    }

    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
      if (xDiff > 0) {
        this.setState((prev) => {
          if (prev.slide <= 5) {
            return {
              slide: prev.slide + 1,
              animation: true
            };
          }
        });
      } else {
        this.setState((prev) => {
          if (prev.slide > 1) {
            return {
              slide: prev.slide - 1,
              animation: true
            }
          }
        });
      }
    } else {
      if (yDiff > 0) {
        this.setState((prev) => {
          if (prev.slide > 1) {
            return {
              slide: prev.slide - 1,
              animation: true
            }
          }
        });
      } else {
        this.setState((prev) => {
          if (prev.slide <= 5) {
            return {
              slide: prev.slide + 1,
              animation: true
            };
          }
        });
      }
    }
    xDown = null;
    yDown = null;
  };

  onScrollHandler(e) {
    const {width, height} = getViewPortSize();

    if (width < 960) {
      const offset = height / 2;
      let current = 1;

      const slide1 = this.slide1.current.getBoundingClientRect().top - offset;
      const slide2 = this.slide2.current.getBoundingClientRect().top - offset;
      const slide3 = this.slide3.current.getBoundingClientRect().top - offset;
      const slide4 = this.slide4.current.getBoundingClientRect().top - offset;
      const slide5 = this.slide5.current.getBoundingClientRect().top - offset;
      const slide6 = this.slide6.current.getBoundingClientRect().top - offset;

      if (slide6 <= 0) {
        current = 6;
      } else if (slide5 <= 0) {
        current = 5;
      } else if (slide4 <= 0) {
        current = 4;
      } else if (slide3 <= 0) {
        current = 3;
      } else if (slide2 <= 0) {
        current = 2;
      }

      this.setState({
        slide: current
      });
    } else {
      if (this.state.animation) {
        return;
      }

      if (e.deltaY > 0) {
        this.setState((prev) => {

          if (prev.slide <= 5) {
            return {
              slide: prev.slide + 1,
              animation: true
            };
          }
        });
      } else {
        this.setState((prev) => {
          if (prev.slide > 1) {
            return {
              slide: prev.slide - 1,
              animation: true
            }
          }
        });
      }
    }
  }

  onKeyUpHandler(e) {
    if (this.state.animation || e.target.tagName === 'INPUT') {
      return;
    }

    if (e.keyCode === 38 || e.keyCode === 37) {
      this.setState((prev) => {
        if (prev.slide > 1) {
          return {
            slide: prev.slide - 1,
            animation: true
          }
        }
      });
    } else if (e.keyCode === 40 || e.keyCode === 39) {
      this.setState((prev) => {

        if (prev.slide <= 5) {
          return {
            slide: prev.slide + 1,
            animation: true
          };
        }
      });
    }
  }

  setSlide(slide) {
    this.setState({
      slide: slide
    });
  }

  onChangeSlideHandler(slide) {
    this.setState({
      slide: slide
    });
  }

  render() {
    const {slide, animation} = this.state;
    const {className, style} = this.props;
    const classes = classNames(
      styles['intro'],
      animation ? styles['intro_state_animation'] : null,
      slide === 1 ? styles['intro_slide_1'] : null,
      slide === 2 ? styles['intro_slide_2'] : null,
      slide === 3 ? styles['intro_slide_3'] : null,
      slide === 4 ? styles['intro_slide_4'] : null,
      slide === 5 ? styles['intro_slide_5'] : null,
      slide === 6 ? styles['intro_slide_6'] : null,
      className
    );

    let linkColor = 'blue';
    let lineColor = 'gray';

    if (slide === 2 || slide === 3 || slide === 4 || slide === 5) {
      linkColor = 'white';
      lineColor = 'white';
    }

    return (
      <div className={classes} style={style} ref={this.intro}>
        <Helmet>
          <body className={styles['is-intro-page']}/>
        </Helmet>
        <Aside
          className={classNames(styles['intro__aside'], styles['intro__aside_left'])}
          color={lineColor}
          bg={'transparent'}
          TopBlock={props => (
            <Link to={'/welcome'} text={'A-Service'} weight={'bold'} {...props} color={'current'}/>
          )}
          BottomBlock={props => (<Navigation active={slide} onClickNavigation={this.setSlide} count={6} {...props}/>)}
        />
        <Aside
          className={classNames(styles['intro__aside'], styles['intro__aside_right'])}
          color={lineColor}
          bg={'transparent'}
          TopBlock={props => (
            <Link text={PHONE} href={`tel:${PHONE}`} weight={'bold'} {...props} color={'current'}/>
          )}
          BottomBlock={props => (
            <Link href={'https://aag.company/'} text={'О компании'} target={'_blank'} weight={'bold'} {...props}
                  color={'current'}/>
          )}
        />
        <div className={styles['intro__inner']}>
          {/* Фоны */}
          <div className={styles['intro__backgrounds']}>
            <div className={styles['intro__background']}/>
            <div className={styles['intro__background']}/>
            <div className={styles['intro__background']}/>
            <div className={styles['intro__background']}/>
            <div className={styles['intro__background']}/>
            <div className={styles['intro__background']}/>
          </div>

          {/* Слайды */}
          <div className={styles['intro__slider']} ref={this.slider}>
            {/* Первый слайд */}
            <div className={styles['intro__slide']} ref={this.slide1}>
              <div className={styles['intro__wrapper']}>
                <div className={styles['intro__content']}>
                  <div className={styles['intro__top']}>
                    <IconLogo className={styles['intro__logo']}/>
                  </div>
                  <h1 className={styles['intro__heading']}>
                    Управление квартирой <span className={'nowrap'}>в одном сервисе</span>
                  </h1>
                  <div className={styles['intro__auth']}>
                    <Authentication/>
                  </div>
                </div>
                <div className={styles['intro__picture']}>
                  <img className={styles['intro__image']} src={imagePhone} alt={'Мобильное приложение'}/>
                </div>
              </div>
            </div>
            {/* Второй слайд */}
            <div className={styles['intro__slide']} ref={this.slide2}>
              <div className={styles['intro__content']}>
                <div className={styles['intro__top']}>ЗАПУСК В&nbsp;2019</div>
                <h2 className={styles['intro__heading']}>Сервис, с&nbsp;которым просто</h2>
                <div className={styles['intro__picture']}>
                  <span/>
                  <span/>
                  <span/>
                  <img src={imageRadar} className={styles['intro__image']} alt={'Мобильное приложение'}/>
                  <IconChat className={styles['intro__chat']}/>
                  <IconKey className={styles['intro__key']}/>
                </div>
                <div className={styles['intro__description']}>
                  Для жильцов мы запустили сервис, с которым вести бытовые дела очень просто. Все функции сервиса доступны для зарегистрированных собственников управляющей компании AAG.
                </div>
              </div>
            </div>
            {/* Третий слайд */}
            <div className={styles['intro__slide']} ref={this.slide3}>
              <div className={styles['intro__content']}>
                <h2 className={styles['intro__heading']}>Всё важное о&nbsp;квартире в&nbsp;личном кабинете</h2>
                <div className={styles['intro__wrapper']}>
                  <div className={styles['intro__items']}>
                    <div className={styles['intro__item']}>
                      <h3>Соседи</h3>
                      <Image src={imageNeighbors} alt={'Соседи'} className={styles['intro__image']}/>
                      <ul>
                        <li>Социальная сеть жильцов дома</li>
                        <li>Форум с обсуждением всех актуальных тем</li>
                        <li>Результаты собраний и голосований</li>
                      </ul>
                    </div>
                    <div className={styles['intro__item']}>
                      <h3>Дом</h3>
                      <Image src={imageHouse} alt={'Соседи'} className={styles['intro__image']}/>
                      <ul>
                        <li>Оплата квитанций, внесение показаний счетчиков</li>
                        <li>Видеосигнал с камер наблюдения дома</li>
                        <li>Заявка на выполнение работ по квартире и дому</li>
                      </ul>
                      <div className={styles['intro__more']}>
                        <div className={styles['intro__icon']}>
                          <IconMore/>
                        </div>
                        <div className={styles['intro__text']}>
                          Для клиентов управляющей компании AAG
                        </div>
                      </div>
                    </div>
                    <div className={styles['intro__item']}>
                      <h3>Район</h3>
                      <Image src={imageDistrict} alt={'Соседи'} className={styles['intro__image']}/>
                      <ul>
                        <li>Инфраструктура района</li>
                        <li>Полезные контакты ближайших учреждений</li>
                      </ul>
                      <div className={styles['intro__more']}>
                        <div className={styles['intro__icon']}>
                          <IconMore/>
                        </div>
                        <div className={styles['intro__text']}>
                          Для клиентов управляющей компании AAG
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Четвертый слайд */}
            <div className={styles['intro__slide']} ref={this.slide4}>
              <div className={styles['intro__content']}>
                <h2 className={styles['intro__heading']}>Бонусы от&nbsp;вашей управляющей компании</h2>
                <div className={styles['intro__picture']}>
                  <img src={imageService} alt={'А-Сервис'} className={styles['intro__image']}/>
                  <img src={imageText} alt={'Услуги'} className={styles['intro__image']}/>
                </div>
                <ul>
                  <li>Постоянные скидки для клиентов</li>
                  <li>Спецпредложения на&nbsp;кухни, бытовую технику, ремонт, дизайн, отделку и&nbsp;не&nbsp;только</li>
                  <li>Услуги от&nbsp;проверенных партнеров</li>
                </ul>
              </div>
            </div>
            {/* Пятый слайд */}
            <div className={styles['intro__slide']} ref={this.slide5}>
              <div className={styles['intro__wrapper']}>
                <div className={styles['intro__picture']}>
                  <img src={imageFounder} alt={'Основатель компании'} className={styles['intro__image']}/>
                </div>
                <div className={styles['intro__content']}>
                  <h2>
                    Друзья! Благодарю вас за&nbsp;доверие к&nbsp;нашей компании и&nbsp;напоминаю, что при проектировании
                    и&nbsp;строительстве мы&nbsp;продумали до&nbsp;мелочей каждую деталь, чтобы создать дом, который
                    вы&nbsp;выбрали.
                  </h2>
                  <h2>
                    Не&nbsp;позволяйте отнять у&nbsp;вас качество жизни, доверив управление
                    тем, кто далёк от&nbsp;философии комфорта и&nbsp;уважения к&nbsp;каждой детали.
                  </h2>
                  <div className={styles['intro__quote']}>
                    <IconQuote className={styles['intro__icon']}/>
                    <div className={styles['intro__quote-text']}>
                      <div className={styles['intro__name']}>Александр Завьялов</div>
                      <div className={styles['intro__post']}>Генеральный директор AAG</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Шестой слайд */}
            <div className={styles['intro__slide']} ref={this.slide6}>
              <div className={styles['intro__wrapper']}>
                <div className={styles['intro__picture']}>
                  <IconLogo2 className={styles['intro__image']}/>
                </div>
                <div className={styles['intro__content']}>
                  <h2>Управляющая компания<br/>и&nbsp;застройщик</h2>
                  <ul>
                    <li>11&nbsp;лет в&nbsp;сфере недвижимости</li>
                    <li>Уникальные проекты</li>
                    <li>Обслуживание и&nbsp;эксплуатация</li>
                    <li>Дома комфорт+, бизнес и&nbsp;премиум классов</li>
                  </ul>
                  <div className={styles['intro__company']}>
                    <Link
                      href={'https://aag.company/'}
                      color={'blue'}
                      weight={'bold'}
                      target={'_blank'}
                      text={'Перейти на&nbsp;сайт холдинга AAG'}
                      IconRight={(props) => (<IconArrowRight {...props} color={'red'}/>)}
                    />
                  </div>
                </div>
              </div>
              <Footer pathname={'/'} className={styles['intro__footer']}/>
            </div>
          </div>
          {/* Доп элементы для анимации */}
          <div className={styles['intro__animations']}>
            <div className={styles['intro__line_1']}/>
            <div className={styles['intro__line_2']}/>
            <div className={styles['intro__line_3']}/>
            <div className={styles['intro__line_4']}/>
            <div className={styles['intro__line_5']}/>
            <div className={styles['intro__triangle_1']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_2']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_3']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_4']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_5']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_6']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_7']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__triangle_8']}>
              <IconTriangle/>
            </div>
            <div className={styles['intro__circle_1']}/>
            <div className={styles['intro__circle_2']}/>
            <div className={styles['intro__circle_3']}/>
            <div className={styles['intro__circle_4']}/>
          </div>
        </div>
      </div>
    )
  }
}

Intro.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

export default Intro;
