import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';


import classNames from 'classnames';
import styles     from './FormError.less';

class FormError extends PureComponent {
  render() {
    const {className, style, error} = this.props;
    const classes = classNames(styles['form-error'], className);

    return (
      <div className={classes} style={style} dangerouslySetInnerHTML={{__html: error}}/>
    )
  }
}

FormError.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текст ошибки
   */
  error: PropTypes.string
};

export default FormError;
