import client        from '../graph.js';
import sendCode      from './graphql/sendCode.graphql';
import signUp        from './graphql/signUp.graphql';
import signIn        from './graphql/signIn.graphql';
import signInByCode  from './graphql/signInByCode.graphql';
import signOut       from './graphql/signOut.graphql';
import getUserData   from './graphql/getUserData.graphql';
import updateProfile from './graphql/updateProfile.graphql';
import subscribe     from './graphql/subscribe.graphql';

/**
 * Отправить запрос на создание телефона
 * @param {string} phone - номер телефона
 * @param {boolean} createIfNotExistByPhone - создать пользователя, если не существует
 * @return {Promise} - вернет время повторной отправки смс.
 */
export function fetchSendCode(phone, createIfNotExistByPhone) {
  return client.mutate({
    mutation: sendCode,
    fetchPolicy: 'no-cache',
    variables: {
      phone,
      createIfNotExistByPhone
    }
  })
    .then(response => {
      const result = response['data']['User']['sendCode'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      return {
        time: result['resendTime']
      }
    })
}

/**
 * Отправить запрос на регистрацию
 * @param {string} phone - номер телефона
 * @param {string} code - код из смс
 * @return {Promise} - вернет уникальный токен пользователя
 */
export function fetchSignUp(phone, code) {
  return client.mutate({
    mutation: signUp,
    fetchPolicy: 'no-cache',
    variables: {
      phone,
      confirmCode: code
    }
  })
    .then(response => {
      const result = response['data']['User']['signUp'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      const phone = result.user.phone;

      result.user.phone = phone
        ? ('' + 8 + ' (' + phone[1] + '' + phone[2] + '' + phone[3] + ') ' + phone[4] + '' + phone[5] + ''
          + phone[6] + '-' + phone[7] + '' + phone[8] + '-' + phone[9] + '' + phone[10])
        : null;

      return result;
    })
}

/**
 * Отправить запрос на авторизацию по коду из смс
 * @param {string} phone - номер телефона
 * @param {string} code - код из смс
 * @return {Promise} - вернет уникальный токен пользователя
 */
export function fetchSignInByCode(phone, code) {
  return client.mutate({
    mutation: signInByCode, // aka forgotPassword
    fetchPolicy: 'no-cache',
    variables: {
      phone,
      confirmCode: code
    }
  })
    .then(response => {
      const result = response['data']['User']['forgotPassword'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      const phone = result.user.phone;

      result.user.phone = phone
        ? ('' + 8 + ' (' + phone[1] + '' + phone[2] + '' + phone[3] + ') ' + phone[4] + '' + phone[5] + ''
          + phone[6] + '-' + phone[7] + '' + phone[8] + '-' + phone[9] + '' + phone[10])
        : null;

      return result;
    })
}

/**
 * Отправить запрос на вход
 * @param {string} phone - номер телефона
 * @param {string} password - пароль
 * @return {Promise} - вернет уникальный токен пользователя
 */
export function fetchSignIn(phone, password) {
  return client.mutate({
    mutation: signIn,
    variables: {
      phone,
      password
    }
  })
    .then(response => {
      const result = response['data']['User']['signIn'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      const phone = result.user.phone;

      result.user.phone = phone
        ? ('' + 8 + ' (' + phone[1] + '' + phone[2] + '' + phone[3] + ') ' + phone[4] + '' + phone[5] + ''
          + phone[6] + '-' + phone[7] + '' + phone[8] + '-' + phone[9] + '' + phone[10])
        : null;

      return result
    })
}

/**
 * Отправить запрос за данными пользователя
 * @param {string} token - токен пользователя
 * @return {Promise} - вернет данные пользователя
 */
export function fetchUserData(token) {
  return client.query({
    query: getUserData,
    fetchPolicy: 'no-cache',
    variables: {
      token
    }
  })
    .then(response => {
      const result = response['data']['User'];
      const phone = result.phone;

      return result
    })
}

/**
 * Отправить запрос на выход
 * @param {string} token - токен пользователя
 * @return {undefined} -
 */
export function fetchSignOut(token) {
  client.mutate({
    mutation: signOut,
    variables: {
      token
    }
  })
}

/**
 * Отправить запрос на подписку
 * @param {string} email - токен пользователя
 * @return {Promise} -
 */
export function fetchSubscribe(email) {
  return client.mutate({
    mutation: subscribe,
    variables: {
      email
    }
  })
    .then(response => {
      const result = response['data']['User']['subscribe'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      return result
    })
}

/**
 * Отправить запрос на изменение данных пользователя
 * @param {string} token - токен пользователя
 * @param {string} config - токен пользователя
 * @return {Promise} -
 */
export function fetchUpdateProfile(token, config) {
  return client.mutate({
    mutation: updateProfile,
    variables: {
      token,
      ...config
    }
  })
    .then(response => {
      const result = response['data']['User']['updateProfile'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      return result
    })
}
