import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Input.less';

import {IconLock} from 'Icons';

import cases     from './Input.cases';
import InputMask from 'inputmask';

class Input extends PureComponent {
  constructor(props) {
    super(props);

    let type = props.type === 'password' ? props.type : 'text';

    if (props.type === 'code' || props.type === 'phone' || props.type === 'number') {
      type = 'tel';
    }

    this.state = {
      /**
       * Наведен курсор мыши на элемент
       */
      isStateMouseEnter: false,
      /**
       * Установлен фокус на элемент
       */
      isStateFocus: false,
      /**
       * Показывать ошибку
       */
      isStateError: false,
      /**
       * Сообщение об ошибке
       */
      errorMessage: '',
      /**
       * Тип поля
       */
      type: type,
      /**
       * Кеймы тестирования
       */
      cases: cases[props.type]
    };

    this.field = React.createRef();

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.onClickRightIconHandler = this.onClickRightIconHandler.bind(this);
    this.onMouseDownRightIconHandler = this.onMouseDownRightIconHandler.bind(this);
    this.onMouseUpRightIconHandler = this.onMouseUpRightIconHandler.bind(this);

    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    if (this.props.type === 'phone') {
      InputMask({
        mask: '+79999999999',
        placeholder: '',
        showMaskOnHover: false,
        showMaskOnFocus: true,
      }).mask(this.field)
    }

    if (this.props.type === 'code') {
      InputMask({
        mask: '999999',
        placeholder: '',
        showMaskOnHover: false,
        showMaskOnFocus: false,
      }).mask(this.field)
    }
  }

  componentWillUnmount() {
    if (this.props.type === 'tel' || this.props.type === 'sms') {
      InputMask().remove(this._field);
    }
  }

  /**
   * Обработчик события наведения курсора на поле ввода
   * @param {object} event - событие
   */
  onMouseEnterHandler(event) {
    this.setState({
      isStateMouseEnter: true
    });

    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(event, event.target.value);
    }
  }

  /**
   * Обработчик события ухода курсора с поля ввода
   * @param {object} event - событие
   */
  onMouseLeaveHandler(event) {
    this.setState({
      isStateMouseEnter: false
    });

    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event, event.target.value);
    }
  }

  /**
   * Обработчик клика по полю ввода
   * @param {object} event - событие
   */
  onClickHandler(event) {
    if (this.props.onClick) {
      this.props.onClick(event, event.target.value);
    }
  }

  /**
   * Обработчик события установки фокуса в поле ввода
   * @param {object} event - событие
   */
  onFocusHandler(event) {
    this.setState({
      isStateFocus: true
    });

    if (this.props.onFocus) {
      this.props.onFocus(event, event.target.value);
    }
  }

  /**
   * Обработчик события снятия фокуса с поля ввода
   * @param {object} event - событие
   */
  onBlurHandler(event) {
    this.setState({
      isStateFocus: false
    });

    if (!this.props.disableValidation && event.target.value.trim()) {
      this.validate('onBlur');
    }

    if (this.props.onBlur) {
      this.props.onBlur(event, event.target.value);
    }
  }

  /**
   * Обработчик события изменения значения поля ввода
   * @param {object} event - событие
   */
  onChangeHandler(event) {
    if (!this.props.disableValidation) {
      this.validate('onChange');
    }

    if (this.props.onChange) {
      this.props.onChange(event, event.target.value);
    }
  }

  /**
   * Обработчик события при клике по правой иконке
   * @param {object} event - событие
   * @return {undefined} -
   */
  onClickRightIconHandler(event) {
    if (this.props.onClickIconRight) {
      this.props.onClickIconRight(event, event.target.value);
    }
  }

  /**
   * Обработчик события при нажатии правой иконки
   * @param {object} event - событие
   * @return {undefined} -
   */
  onMouseDownRightIconHandler(event) {
    if (this.props.type === 'password') {
      this.setState({
        type: 'text'
      })
    }
  }

  /**
   * Обработчик события при отпуске правой иконки
   * @param {object} event - событие
   * @return {undefined} -
   */
  onMouseUpRightIconHandler(event) {
    if (this.props.type === 'password') {
      this.setState({
        type: 'password'
      })
    }
  }

  /**
   * Установить фокус в поле ввода
   */
  focus() {
    this.field.current.focus()
  }

  /**
   * Убрать фокус из поля ввода
   */
  blur() {
    this.field.current.blur()
  }

  /**
   * Проверить поле на валидность
   * @param {string} action - по какому событию происходит валидация
   * @return {string}
   */
  validate(action) {
    let message = '';

    if (this.props.allowEmpty && !this.props.value.trim()) {
      this.setState({
        errorMessage: '',
        isStateError: false
      });

      return message;
    }

    if (this.state.cases && this.state.cases[action]) {
      const cases = Array.isArray(this.state.cases[action]) ? this.state.cases[action] : [this.state.cases[action]];

      cases.map((test) => {
        if (!test.case(this.props.value.trim())) {
          message = test.message(this.props.label);
        }
      })
    }

    if (message) {
      this.setState({
        errorMessage: message,
        isStateError: true
      })
    } else {
      this.setState({
        errorMessage: '',
        isStateError: false
      })
    }

    return message;
  }

  render() {
    const {type, isStateMouseEnter, isStateFocus, isStateError, errorMessage} = this.state;
    const {className, style, label, id, value, name, disabled, size, IconLeft, readOnly, IconRight} = this.props;

    let Icon;

    if (this.props.type === 'password') {
      Icon = <IconLock/>
    } else if (IconRight) {
      Icon = <IconRight/>
    }

    const classes = classNames(
      styles['input'],
      size === 's' ? styles['input_size_s'] : null,
      size === 'm' ? styles['input_size_m'] : null,
      size === 'l' ? styles['input_size_l'] : null,
      disabled ? styles['input_state_disabled'] : null,
      isStateMouseEnter ? styles['input_state_hover'] : null,
      isStateFocus ? styles['input_state_focus'] : null,
      isStateError ? styles['input_state_error'] : null,
      Icon ? styles['input_icon_right'] : null,
      IconLeft ? styles['input_icon_left'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {label && (
          <label className={styles['input__label']} htmlFor={id} dangerouslySetInnerHTML={{__html: label}}/>
        )}
        <div className={styles['input__box']}>
          {IconLeft && (
            <span className={styles['input__aside input__aside_left']}>
                <IconLeft/>
              </span>
          )}
          <input
            className={styles['input__field']}
            autoComplete="false"
            spellCheck="false"
            autoCapitalize="false"
            autoCorrect="false"
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
            onClick={this.onClickHandler}
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
            onChange={this.onChangeHandler}
            id={id}
            ref={this.field}
          />
          {Icon && (
            <button
              className={classNames(styles['input__icon'], styles['input__icon_right'])}
              type={'button'}
              onClick={this.onClickRightIconHandler}
              onMouseDown={this.onMouseDownRightIconHandler}
              onMouseUp={this.onMouseUpRightIconHandler}
            >
              {Icon}
            </button>
          )}
        </div>
        <div className={styles['input__message']} dangerouslySetInnerHTML={{__html: errorMessage}}/>
      </div>
    )
  }
}

Input.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Заголовок поля
   */
  label: PropTypes.string,
  /**
   * Тип поля ввода
   */
  type: PropTypes.oneOf(['text', 'email', 'password', 'name', 'number', 'phone', 'code', 'time', 'date']).isRequired,
  /**
   * Значение поля
   */
  value: PropTypes.any.isRequired,
  /**
   * Название поля
   */
  name: PropTypes.string.isRequired,
  /**
   * Уникальный идентификатор поля
   */
  id: PropTypes.string.isRequired,
  /**
   * Заблокировано ли поле ввода
   */
  disabled: PropTypes.bool,
  /**
   * Элемент доступен только для чтения
   */
  readOnly: PropTypes.bool,
  /**
   * Обязательно ли поле ввода
   */
  required: PropTypes.bool,
  /**
   * Обработчик события наведения курсора на поле ввода
   */
  onMouseEnter: PropTypes.func,
  /**
   * Обработчик события ухода курсора с поля ввода
   */
  onMouseLeave: PropTypes.func,
  /**
   * Обработчик события установки клика по полю ввода
   */
  onClick: PropTypes.func,
  /**
   * Обработчик события установки фокуса в поле ввода
   */
  onFocus: PropTypes.func,
  /**
   * Обработчик события снятия фокуса с поля ввода
   */
  onBlur: PropTypes.func,
  /**
   * Обработчик события изменения поля (работает как onInput)
   * (https://stackoverflow.com/questions/38256332/in-react-whats-the-difference-between-onchange-and-oninput)
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Размер поля ввода
   */
  size: PropTypes.oneOf(['s', 'm', 'l']).isRequired,
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Событие при нажатии на левую иконку
   */
  onClickIconLeft: PropTypes.func,
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconRight: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Событие при нажатии на правую иконку
   */
  onClickIconRight: PropTypes.func,
  /**
   * Поле может быть пустым
   */
  allowEmpty: PropTypes.bool,
  /**
   * Отключить автовалидацию поля
   */
  disableValidation: PropTypes.bool
};

export default Input;
