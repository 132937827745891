import React, {Component} from 'react';
import PropTypes          from 'prop-types';

import classNames from 'classnames';
import styles     from './ConfirmPhone.less';

import moment from 'moment';

import {IconArrowRight}                                                       from 'Icons';
import {Button, Input, Form, FormBox, FormField, FormAction, FormError, Link} from 'Elements';
import { PHONE } from '../../utils/constants';

const ONE_SECOND = 1000;

class ConfirmPhone extends Component {
  constructor(props) {
    super(props);

    const available = moment.utc(props.delay).format('X');
    const current = moment.utc().format('X');
    this._mounted = false;

    this.state = {
      available: available,
      delay: available - current,
      resend: available - current < 0,
      error: '',
      values: {
        code: '',
      }
    };

    this.form = React.createRef();
    this.code = React.createRef();

    this.countDown = this.countDown.bind(this);
    this.onChangeFieldHandler = this.onChangeFieldHandler.bind(this);
    this.onSubmitCodeHandler = this.onSubmitCodeHandler.bind(this);
    this.onResendCodeHandler = this.onResendCodeHandler.bind(this);
  }

  componentDidMount() {
    if (!this.state.resend) {
      this.timer = setInterval(this.countDown, ONE_SECOND);
    }
    this._mounted = true;
    this.code.current.focus();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this._mounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    const available = moment.utc(props.delay).format('X');
    const current = moment.utc().format('X');

    if (available !== state.available) {
      return {
        available: available,
        delay: available - current,
        resend: available - current < 0
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.resend && prevProps.delay !== this.props.delay) {
      this.timer = setInterval(this.countDown, ONE_SECOND);
    }
  }

  countDown() {
    if (this.state.delay <= 1) {
      clearInterval(this.timer);

      this.setState({
        delay: 0,
        resend: true
      });

      return;
    }

    this.setState(prevState => {
      return {
        delay: prevState.delay - 1
      }
    });
  }

  onResendCodeHandler() {
    this.setState({
      resend: false
    });

    this.props.onResendCode()
  }

  onChangeFieldHandler(event, value) {
    const name = event.target.name;
    this.setState(state => {
      return {
        values: {
          ...state.values,
          [name]: value
        }
      }
    })
  }

  onSubmitCodeHandler() {
    this.form.current.validate()
      .then(() => {
        this.setState({
          error: ''
        })
      })
      .then(() => {
        return this.props.onSubmitCode(this.state.values.code)
          .then(() => {
            return this.props.onConfirmPhone()
          })
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          });
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err)
      })
  }

  render() {
    const {className, style, isLoading} = this.props;
    const {delay, resend, error} = this.state;
    const {code} = this.state.values;
    const time = moment().startOf('day').add(delay > 0 ? delay : 0, 'second').format('m:ss');
    const classes = classNames(styles['confirm-phone'], resend ? styles['confirm-phone_state_resend'] : null, className);

    return (
      <div className={classes} style={style}>
        <div className={styles['confirm-phone__info']}>
          <p>
            На&nbsp;ваш номер отправлен шестизначный код. Введите его в&nbsp;поле, чтобы подтвердить запись
            на&nbsp;получение ключей.
          </p>
          <p>Для записи на&nbsp;ключи используйте номер телефона, который вы&nbsp;указывали в&nbsp;договоре. Кроме телефона, код дублируется на&nbsp;почту, указанную в&nbsp;договоре.</p>
          <p>
            Если код не&nbsp;пришел, позвоните нам по&nbsp;номеру <Link
            underline={true} color={'current'} text={PHONE} href={`tel:${PHONE}`}/>
            <br/>
            Мы&nbsp;работаем с&nbsp;понедельника по&nbsp;пятницу с&nbsp;10:00 до&nbsp;19:00.
          </p>
        </div>
        <Form onSubmit={this.onSubmitCodeHandler} fields={[this.code]} ref={this.form}>
          <FormBox>
            <FormField size={'l'} count={'single'} withAction={true}>
              <Input
                label={'Код'}
                type={'code'}
                name={'code'}
                id={'confirm-phone-id'}
                size={'l'}
                value={code}
                validation={true}
                onChange={this.onChangeFieldHandler}
                ref={this.code}
              />
            </FormField>
            <FormAction size={'l'}>
              <Button
                color={'red'}
                size={'l'}
                text={'Подтвердить'}
                loading={isLoading}
                hiddenTextOnDesktop={true}
                IconRight={props => <IconArrowRight {...props}/>}
                onClick={this.confirmPhone}
              />
            </FormAction>
          </FormBox>
          {error && (
            <FormError error={error}/>
          )}
        </Form>
        <div className={styles['confirm-phone__inner']}>
          <span className={styles['confirm-phone__timer']} dangerouslySetInnerHTML={{__html: time}}/>
          <button
            className={styles['confirm-phone__send']}
            dangerouslySetInnerHTML={{__html: 'Отправить новый код'}}
            onClick={this.onResendCodeHandler}
          />
        </div>
      </div>
    )
  }
}

ConfirmPhone.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.string,
  /**
   * Номер телефона, проходящий валидацию
   */
  phone: PropTypes.string.isRequired,
  /**
   * Время до которого нельзя отправлять смс (в формате ISO 8601)
   */
  delay: PropTypes.string.isRequired,
  /**
   * Обработчик события подтверждения кода
   */
  onSubmitCode: PropTypes.func.isRequired,
  /**
   * Обработчик события после отправки onSubmitCode
   */
  onConfirmPhone: PropTypes.func.isRequired,
  /**
   * Обработчик события отправки нового кода
   */
  onResendCode: PropTypes.func.isRequired,
  /**
   * Происходит проверка кода
   */
  isLoading: PropTypes.bool
};

export default ConfirmPhone;
