exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-action--215TS {\n  padding: 10px;\n  -webkit-box-flex: 0;\n  -webkit-flex: 0 0 auto;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto;\n  -webkit-align-self: flex-end;\n      -ms-flex-item-align: end;\n          align-self: flex-end;\n  margin: 0 0 0 auto;\n}\n@media (min-width: 768px) {\n  .form-action--215TS {\n    padding: 19px 10px;\n  }\n}\n.form-action_size_s--w6PJv {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .form-action_size_s--w6PJv {\n    width: 72px;\n  }\n}\n.form-action_size_m--3yE9l {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .form-action_size_m--3yE9l {\n    width: 77px;\n  }\n}\n.form-action_size_l--3HpfE {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .form-action_size_l--3HpfE {\n    width: 82px;\n  }\n}\n.form-action_size_fill--2ZVcO {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .form-action_size_fill--2ZVcO {\n    width: auto;\n  }\n}\n@media (max-width: 767px) {\n  .form-action_in_block--3KN7d {\n    width: auto;\n    -webkit-box-flex: 1;\n    -webkit-flex: 1 1 auto;\n        -ms-flex: 1 1 auto;\n            flex: 1 1 auto;\n    margin: 0 -22px -24px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"form-action": "form-action--215TS",
	"form-action_size_s": "form-action_size_s--w6PJv",
	"form-action_size_m": "form-action_size_m--3yE9l",
	"form-action_size_l": "form-action_size_l--3HpfE",
	"form-action_size_fill": "form-action_size_fill--2ZVcO",
	"form-action_in_block": "form-action_in_block--3KN7d"
};