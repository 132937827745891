import * as services from './content.services';

/**
 * Пошел запрос за контентом страницы
 * @type {string}
 */
export const GET_PAGE_CONTENT_REQUEST = 'GET_PAGE_CONTENT_REQUEST';
/**
 * Запрос за контентом страницы завершился успешно
 * @type {string}
 */
export const GET_PAGE_CONTENT_SUCCESS = 'GET_PAGE_CONTENT_SUCCESS';
/**
 * Запрос за контентом страницы завершился ошибкой
 * @type {string}
 */
export const GET_PAGE_CONTENT_FAILURE = 'GET_PAGE_CONTENT_FAILURE';
/**
 * Пошел запрос за контентом блока
 * @type {string}
 */
export const GET_BLOCK_CONTENT_REQUEST = 'GET_BLOCK_CONTENT_REQUEST';
/**
 * Запрос за контентом блока завершился успешно
 * @type {string}
 */
export const GET_BLOCK_CONTENT_SUCCESS = 'GET_BLOCK_CONTENT_SUCCESS';
/**
 * Запрос за контентом блока завершился ошибкой
 * @type {string}
 */
export const GET_BLOCK_CONTENT_FAILURE = 'GET_BLOCK_CONTENT_FAILURE';

/**
 * Генератор действия - получить данные страницы
 * @param {string} name - название (тип) страницы
 * @param {string} url - адрес страницы
 * @param {object} config - доп. конфиг
 * @return {function} - обновленное состояние хранилища
 */
function getPageContent(name, url, config) {
  return (dispatch, getStore) => {
    dispatch(getPageContentRequest(name, url, config));

    return services.fetchPageContent(name, url, config)
      .then(result => dispatch(getPageContentSuccess(name, url, result)))
      .catch((err) => dispatch(getPageContentFailure(name, url, err)));
  };

  /**
   * Генератор действия - пошел запрос за данными страницы
   * @param {string} name - название (тип) страницы
   * @param {string} url - адрес страницы
   * @param {object} config - доп. конфиг
   * @return {function} - обновленное состояние хранилища
   */
  function getPageContentRequest(name, url, config) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_PAGE_CONTENT_REQUEST,
            name: name,
            url: url,
            config: config
          })
        })
    }
  }

  /**
   * Генератор действия - запрос за данными страницы завершился успешно
   * @param {string} name - название (тип) страницы
   * @param {string} url - адрес страницы
   * @param {object} result - параметры страницы
   * @return {function} - обновленное состояние хранилища
   */
  function getPageContentSuccess(name, url, result) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_PAGE_CONTENT_SUCCESS,
            name: name,
            url: url,
            config: config,
            meta: result.meta,
            content: result.content,
            settings: result.settings
          })
        })
    }
  }

  /**
   * Генератор действия - запрос за данными страницы завершился ошибкой
   * @param {string} name - название (тип) страницы
   * @param {string} url - адрес страницы
   * @param {error} err - ошибка
   * @return {function} - обновленное состояние хранилища
   */
  function getPageContentFailure(name, url, err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_PAGE_CONTENT_FAILURE,
            name: name,
            url: url,
            err: err
          })
        })
        .then(() => {
          throw err
        })
    }
  }
}

/**
 * Генератор действия - получить данные блока
 * @param {string} name - название (тип) блока
 * @param {object} config - доп. конфиг
 * @return {function} - обновленное состояние хранилища
 */
function getBlockContent(name, config) {
  return (dispatch, getStore) => {
    dispatch(getBlockContentRequest(name, config));

    return services.fetchBlockContent(name, config)
      .then(result => dispatch(getBlockContentSuccess(name, result)))
      .catch((err) => dispatch(getBlockContentFailure(name, err)));
  };

  /**
   * Генератор действия - пошел запрос за данными блока
   * @param {string} name - название (тип) блока
   * @param {object} config - доп. конфиг
   * @return {function} - обновленное состояние хранилища
   */
  function getBlockContentRequest(name, config) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_BLOCK_CONTENT_REQUEST,
            name: name,
            config: config
          })
        })
    }
  }

  /**
   * Генератор действия - запрос за данными блока завершился успешно
   * @param {string} name - название (тип) блока
   * @param {object} result - параметры блока
   * @return {function} - обновленное состояние хранилища
   */
  function getBlockContentSuccess(name, result) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_BLOCK_CONTENT_SUCCESS,
            name: name,
            config: config,
            meta: result.meta,
            content: result.content,
            settings: result.settings
          })
        })
    }
  }

  /**
   * Генератор действия - запрос за данными блока завершился ошибкой
   * @param {string} name - название (тип) блока
   * @param {error} err - ошибка
   * @return {function} - обновленное состояние хранилища
   */
  function getBlockContentFailure(name, err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: GET_BLOCK_CONTENT_FAILURE,
            name: name,
            err: err
          })
        })
        .then(() => {
          throw err
        })
    }
  }
}

