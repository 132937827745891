exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".bookings__value--3uKnQ {\n  font-weight: 800;\n  letter-spacing: -1.9px;\n  font-size: 28px;\n  line-height: 1.28571429;\n}\n@media (min-width: 640px) {\n  .bookings__value--3uKnQ {\n    font-size: 40px;\n    line-height: 1.25;\n  }\n}\n@media (min-width: 768px) {\n  .bookings__value--3uKnQ {\n    min-height: 62px;\n  }\n}\n@media (min-width: 1280px) {\n  .bookings__value--3uKnQ {\n    font-size: 50px;\n    line-height: 1.2;\n  }\n}\n@media (min-width: 1440px) {\n  .bookings__value--3uKnQ {\n    font-size: 70px;\n    line-height: 1.14285714;\n  }\n}\n.bookings__title--3Ysr8 {\n  font-size: 14px;\n  letter-spacing: 0.2px;\n  margin: 4px 0 0;\n}\n@media (min-width: 640px) {\n  .bookings__title--3Ysr8 {\n    font-size: 16px;\n  }\n}\n@media (min-width: 960px) {\n  .bookings__title--3Ysr8 {\n    font-size: 18px;\n  }\n}\n.bookings__title_cancel--3e2id {\n  display: none;\n}\n@media (min-width: 768px) {\n  .bookings__title_cancel--3e2id {\n    display: block;\n  }\n}\n.bookings__more--1h5yk {\n  font-size: 18px;\n  line-height: 1.33333333;\n}\n@media (min-width: 640px) {\n  .bookings__more--1h5yk {\n    font-size: 24px;\n    line-height: 1.16666667;\n  }\n}\n.bookings__more--1h5yk svg {\n  width: 17px;\n  height: 13px;\n  color: #f44336;\n}\n.bookings__download-note--13CnY {\n  color: gray;\n  font-size: 16px;\n  display: inline-block;\n  vertical-align: middle;\n  padding-left: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"bookings__value": "bookings__value--3uKnQ",
	"bookings__title": "bookings__title--3Ysr8",
	"bookings__title_cancel": "bookings__title_cancel--3e2id",
	"bookings__more": "bookings__more--1h5yk",
	"bookings__download-note": "bookings__download-note--13CnY"
};