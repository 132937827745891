exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-error--Wveh2 {\n  margin: 11px 0 0;\n  font-size: 14px;\n  font-weight: 700;\n  color: #f44336;\n}\n", ""]);

// exports
exports.locals = {
	"form-error": "form-error--Wveh2"
};