import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconLoading.less';

import {Icon} from 'Icons';

export default class IconLoading extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-loading'], className);

    return (
      <Icon className={classes} style={style} viewBox={'0 0 28 28'}>
        <path fill="currentColor" d="M14 27.5c-7.4 0-13.5-6.1-13.5-13.5s6.1-13.5 13.5-13.5c3.6 0 7 1.4 9.6 4l-2.1 2.1c-2-2-4.6-3.1-7.4-3.1-5.9 0-10.6 4.7-10.6 10.5s4.7 10.5 10.5 10.5 10.5-4.7 10.5-10.5h3c0 7.4-6.1 13.5-13.5 13.5z"/>
      </Icon>
    )
  }
}

IconLoading.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
