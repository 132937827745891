import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconCheck.less';

import {Icon} from 'Icons';

export default class IconCheck extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-check'], className);

    return (
      <Icon className={classes} style={style} viewBox={'0 0 14.7 11.2'}>
        <path fill="currentColor" d="M6.2 12.7l-5.4-5.2 2.1-2.2 3.1 3.1 7.1-8.4 2.3 2z"/>
      </Icon>
    )
  }
}

IconCheck.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
