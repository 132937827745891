exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon-quote--4AcU_ {\n  width: 35px;\n  height: 27px;\n}\n", ""]);

// exports
exports.locals = {
	"icon-quote": "icon-quote--4AcU_"
};