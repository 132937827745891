import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Form.less';

const SHAKE_ANIMATION_TIME = 500;

class Form extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isStateShake: false
    };

    this.shake = this.shake.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
  }

  onSubmitFormHandler(e) {
    e.preventDefault();

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  shake() {
    this.setState({
      isStateShake: true
    }, () => {
      setTimeout(() => {
        if (this.mounted) {
          this.setState({
            isStateShake: false
          })
        } 
      }, SHAKE_ANIMATION_TIME);
    });
  }

  validate() {
    let focusField = false;
    let message;

    return Promise.resolve()
      .then(() => {
        if (this.props.fields) {
          this.props.fields.map((field) => {
            if (field && field.current && field.current.validate) {
              if (!message) {
                message = field.current.validate('onSubmit');
                if(message) {
                  field.current.focus();
                }
              } else {
                field.current.validate('onSubmit');
              }
            }
          });

          if (message) {
            this.shake();
            throw new Error(message);
          }
        }
      })
  }

  render() {
    const {isStateShake} = this.state;
    const {className, style, children, action, method} = this.props;
    const classes = classNames(
      styles['form'],
      isStateShake ? styles['form_state_shake'] : null,
      className
    );

    return (
      <form className={classes} style={style} onSubmit={this.onSubmitFormHandler} action={action} method={method}>
        {children}
      </form>
    )
  }
}

Form.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Адрес, по которому отправляется форма
   */
  action: PropTypes.string,
  /**
   * Метод отправки формы
   */
  method: PropTypes.oneOf(['GET', 'POST']),
  /**
   * Обработчик события отправки формы
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Массив ссылок на валидируемые поля
   */
  fields: PropTypes.array,
};

export default Form;
