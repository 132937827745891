import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {hot}              from "react-hot-loader";
import {connect}          from "react-redux";

import classNames from 'classnames';
import styles     from './SignUp.less';

import {Form, FormBox, FormAction, FormField, Button, Input, Link, Modal} from 'Elements';
import {ConfirmPhone}                                                     from "Components";

import {sendCode, signUp} from '../../store/user/user.actions';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        phone: ''
      }
    };

    this.form = React.createRef();
    this.phone = React.createRef();
    this.modalConfirm = React.createRef();
    this.confirmPhone = React.createRef();

    this.onChangeFieldHandler = this.onChangeFieldHandler.bind(this);
    this.onSubmitPhoneFormHandler = this.onSubmitPhoneFormHandler.bind(this);
  }

  onChangeFieldHandler(event, value) {
    const name = event.target.name;
    this.setState(state => {
      return {
        values: {
          ...state.values,
          [name]: value
        }
      }
    })
  }

  onSubmitPhoneFormHandler() {
    this.form.current.validate()
      .then(() => {
        this.props.sendCode(this.state.values.phone)
          .then(() => {
            this.modalConfirm.current.openModal();
          })
          .catch(() => {
            this.modalConfirm.current.openModal();
          });
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err)
      });
  }

  render() {
    const {phone} = this.state.values;
    const {className, style, user} = this.props;
    const {isSendingCode} = user;
    const classes = classNames('sign-up', className);

    return (
      <div className={classes} style={style}>
        <Form onSubmit={this.onSubmitPhoneFormHandler} ref={this.form} fields={[this.phone]}>
          <FormBox>
            <FormField size={'l'} count={'fill'}>
              <Input
                label={'Телефон'}
                type={'phone'}
                name={'phone'}
                id={'sign-up-phone'}
                size={'l'}
                value={phone}
                onChange={this.onChangeFieldHandler}
                validation={true}
                ref={this.phone}
              />
            </FormField>
            <FormAction size={'fill'}>
              <Button
                type={'submit'}
                color={'red'}
                size={'l'}
                loading={isSendingCode}
                text={'Записаться'}
                padding={'big'}
              />
            </FormAction>
          </FormBox>
        </Form>
        <div className={styles['sign-up__info']}>
          Нажимая на&nbsp;кнопку, вы&nbsp;соглашаетесь <Link
          underline={true} color={'blue'} weight={'thin'} to={'/privacy'} text={'с&nbsp;политикой конфиденциальности.'}/>
        </div>
        <Modal size={'normal'} label={'Подтвердите телефон'} ref={this.modalConfirm}>
          <ConfirmPhone
            delay={this.props.user.time}
            phone={phone}
            isLoading={this.props.user.isSignUpRequest}
            onSubmitCode={code => this.props.signUp(phone, code)}
            onConfirmPhone={() => {
              this.modalConfirm.current.closeModal();
              this.props.onSignUp();
            }}
            onResendCode={() => this.props.sendCode(phone)}
            ref={this.confirmPhone}
          />
        </Modal>
      </div>
    )
  }
}

SignUp.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Коллбэк при входе
   */
  onSignUp: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (phone) => {
      return dispatch(sendCode(phone))
    },
    signUp: (phone, code) => {
      return dispatch(signUp(phone, code))
    }
  }
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(SignUp));

