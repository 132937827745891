// TODO: Полностью разобраться, что написал :pain:
import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';

import reducers from './reducers';

export const history = createBrowserHistory();
export const connect = connectRouter(history)(reducers);

const middleware = [thunkMiddleware, routerMiddleware(history)];
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

export default function getStore () {
  const preloadedState = window.__PRELOADED_STATE__ || {};
  delete window.__PRELOADED_STATE__;

  return createStore(
    connect,
    preloadedState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  )
}
