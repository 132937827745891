import React, {Component} from 'react';
import PropTypes          from 'prop-types';

import classNames from 'classnames';
import styles     from './Picker.less';

import {Input} from 'Elements';

const picker = (WrappedComponent) => {
  return class Picker extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        value: ''
      };

      this.picker = React.createRef();

      this.onClickHandler = this.onClickHandler.bind(this);
      this.onChangeWrappedComponentHandler = this.onChangeWrappedComponentHandler.bind(this);
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.onClickHandler);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.onClickHandler);
    }

    onClickHandler(event) {
      if (this.picker && this.picker.current && !this.picker.current.contains(event.target)) {
        this.setState({
          show: false
        })
      } else {
        this.setState({
          show: true
        })
      }
    }

    onChangeWrappedComponentHandler(value) {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }

    render() {
      const {value, show} = this.state;
      const {inputProps, ...other} = this.props;
      const classes = classNames(styles['picker'], show ? styles['picker_state_show'] : null);

      return (
        <div className={classes} ref={this.picker}>
          <Input {...inputProps} readOnly={true} onChange={() => {
          }}/>
          <div className={styles['picker__window']}>
            <div className={styles['picker__inner']}>
              <WrappedComponent {...other} onChange={this.onChangeWrappedComponentHandler}/>
            </div>
          </div>
        </div>
      )
    }
  }
};

export default picker;
