exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-more--Nqay5 {\n  margin: 22px 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"form-more": "form-more--Nqay5"
};