import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {connect}          from 'react-redux';
import {hot}              from 'react-hot-loader'

import classNames from 'classnames';
import styles     from './BookingForm.less';

import {IconArrowRight}                                                                  from 'Icons';
import {Button, Calendar, Time, picker, Form, FormBox, FormField, FormAction, FormError, Link } from 'Elements';


import moment from 'moment';

import { PHONE } from '../../../utils/constants';

import {getUserData} from '../../../store/user/user.actions';

import {
  getAvailableDates,
  getAvailableTimes,
  toBookObjects,
  cleanAvailableTimes
} from '../../../store/booking/booking.actions';

const DatePicker = picker(Calendar);
const TimePicker = picker(Time);

class BookingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        date: '',
        time: '',
        error: ''
      }
    };

    this.date = React.createRef();
    this.time = React.createRef();
    this.form = React.createRef();

    this.onChangeDateHandler = this.onChangeDateHandler.bind(this);
    this.onChangeTimeHandler = this.onChangeTimeHandler.bind(this);
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
  }

  componentDidMount() {
    this.props.getAvailableDates(this.props.user.token);
  }

  componentWillUnmount() {
    this.props.cleanAvailableTimes()
  }

  onChangeDateHandler(value) {
    this.setState((state) => {
      return {
        values: {
          time: '',
          date: value
        }
      }
    });

    this.date.current.validate();
    this.props.getAvailableTimes(value);
  }

  onChangeTimeHandler(value) {
    this.setState((state) => {
      return {
        values: {
          ...state.values,
          time: value
        }
      }
    });

    this.time.current.validate();
  }

  onSubmitFormHandler() {
    let message = '';

    return this.form.current.validate()
      .then(() => {
        this.setState({
          error: ''
        })
      })
      .then(() => {
        const {date, time} = this.state.values;
        const mDate = moment.utc(date, 'DD.MM.YYYY');
        const mTime = moment.utc(time, 'HH:mm');
        const result = mDate.add(mTime.format('HH'), 'hours').add(mTime.format('mm'), 'minutes');

        return this.props.toBookObjects(result.toISOString(true), this.props.user.token)
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          });
      })
      .then((response) => {
        message = response.data.message;
        return this.props.getUserData(this.props.user.token)
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          })
      })
      .then(() => {
        if (this.props.onBooking) {
          this.props.onBooking(message);
        }
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err)
      })
  }

  render() {
    const {error} = this.state;
    const {date, time} = this.state.values;
    const {className, style, user, booking} = this.props;
    const {objects} = user;
    const {dates, times, isReserveRequest} = booking;
    const classes = classNames(styles['booking-form'], className);

    return (
      <React.Fragment>
        {/* {dates && dates.free && dates.free.length > 0 ? (
          <div className={classes} style={style}>
            <div className={styles['booking-form__objects']}>
              {objects && objects.map((object) => (
                <div key={object.id} className={styles['booking-form__object']}>
                  <div className={styles['booking-form__value']} dangerouslySetInnerHTML={{__html: object.number}}/>
                  <div className={styles['booking-form__title']} dangerouslySetInnerHTML={{__html: object.type}}/>
                </div>
              ))}
            </div>
            <Form onSubmit={this.onSubmitFormHandler} fields={[this.date, this.time]} ref={this.form}>
              <FormBox>
                <FormField size={'s'} count={'double'} withAction={true}>
                  <DatePicker
                    inputProps={{
                      name: 'date',
                      id: 'booking-form-date',
                      type: 'date',
                      size: 's',
                      label: 'Дата',
                      value: date,
                      readOnly: true,
                      ref: this.date
                    }}
                    from={dates.from ? dates.from : null}
                    to={dates.from ? dates.to : null}
                    free={dates.free ? dates.free : []}
                    onChange={this.onChangeDateHandler}
                  />
                </FormField>
                <FormField size={'s'} count={'double'} withAction={true}>
                  <TimePicker
                    inputProps={{
                      name: 'time',
                      id: 'booking-form-time',
                      type: 'time',
                      size: 's',
                      label: 'Время',
                      value: time,
                      readOnly: true,
                      ref: this.time
                    }}
                    all={times.all ? times.all : []}
                    free={times.free ? times.free : []}
                    onChange={this.onChangeTimeHandler}
                  />
                </FormField>
                <FormAction size={'s'}>
                  <Button
                    type={'submit'}
                    color={'red'}
                    size={'s'}
                    loading={isReserveRequest}
                    hiddenTextOnDesktop={true}
                    text={'Записаться'}
                    IconRight={props => <IconArrowRight {...props}/>}
                  />
                </FormAction>
              </FormBox>
              {error && (
                <FormError error={error}/>
              )}
            </Form>
            <div className={styles['booking-form__more']}>
              Осмотр квартиры, оформление документов и&nbsp;получение ключей для одной квартиры занимает ~45&nbsp;минут. Пожалуйста, приходите на&nbsp;встречу за&nbsp;10&ndash;15 минут до&nbsp;выбранного времени.
            </div>
          </div>
        ) : (
          <div>Свободные часы в&nbsp;период 10&ndash;25 апреля 2019&nbsp;закончились.<br/> Для записи на&nbsp;осмотр на&nbsp;другую дату, пожалуйста, позвоните нам по&nbsp;номеру <a className={styles['phone-link']} href={`tel:${PHONE}`} as={'a'}>{PHONE}</a>.</div>
        )} */}
        <div>Свободные часы в&nbsp;период 10&ndash;25 апреля 2019&nbsp;закончились.<br/> Для записи на&nbsp;осмотр на&nbsp;другую дату, пожалуйста, позвоните нам по&nbsp;номеру <a className={styles['phone-link']} href={`tel:${PHONE}`} as={'a'}>{PHONE}</a>.</div>
      </React.Fragment>
    )   
  }
}

BookingForm.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.string,
  /**
   * Коллбэк при бронировании
   */
  onBooking: PropTypes.func,
  /**
   * Хранилище пользователя
   */
  user: PropTypes.object,
  /**
   * Хранилище бронирований
   */
  booking: PropTypes.object,
};


const mapStateToProps = (state) => {
  return {
    user: state.user,
    booking: state.booking
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    toBookObjects: (datetime, token) => {
      return dispatch(toBookObjects(datetime, token));
    },
    getUserData: (token) => {
      return dispatch(getUserData(token));
    },
    getAvailableDates: (token) => {
      return dispatch(getAvailableDates(token))
    },
    getAvailableTimes: (date) => {
      return dispatch(getAvailableTimes(date))
    },
    cleanAvailableTimes: () => {
      return dispatch(cleanAvailableTimes())
    }
  }
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(BookingForm));
