exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".navigation--3fAXF {\n  margin: -5px;\n}\n.navigation__item--2orOz {\n  color: currentColor;\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  height: 34px;\n  width: 34px;\n  padding: 5px;\n  border: none;\n  -webkit-transform: rotate(90deg);\n      -ms-transform: rotate(90deg);\n          transform: rotate(90deg);\n  font-size: 14px;\n  font-weight: bold;\n  letter-spacing: 1.1px;\n  background-color: transparent;\n  cursor: pointer;\n  -webkit-transition: none;\n  -o-transition: none;\n  transition: none;\n}\n.navigation__item--2orOz:hover {\n  color: #f3665d;\n}\n.navigation__item--2orOz:focus {\n  outline: none;\n}\n.navigation__item_active--1vX2c {\n  color: #f44336;\n  cursor: default;\n}\n@-moz-document url-prefix() {\n  .navigation--3fAXF {\n    margin-left: -1px;\n    transform: translateX(-50%) rotate(-180deg);\n  }\n  .navigation__item--2orOz {\n    transform: rotate(-360deg);\n  }\n}\n", ""]);

// exports
exports.locals = {
	"navigation": "navigation--3fAXF",
	"navigation__item": "navigation__item--2orOz",
	"navigation__item_active": "navigation__item_active--1vX2c"
};