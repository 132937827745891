import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Time.less';

import moment from 'moment';

class Time extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: ''
    }
  }

  onClickTimeHandler(time) {
    this.setState({
      selected: time
    });

    if (this.props.onChange) {
      this.props.onChange(moment.utc(time).format('HH:mm'))
    }
  }

  render() {
    const {selected} = this.state;
    const {className, style, free, all} = this.props;
    const classes = classNames(styles['time'], className);

    return (
      <div className={classes} style={style}>
        <div className={styles['time__items']}>
          {all && all.length ? all.map((item, index) => {
            let isFree = false;
            const classes = classNames(styles['time__item'], selected === item ? styles['time__item_selected'] : null);

            free.map(time => {
              if (time === item) {
                isFree = true;
              }
            });

            if (!isFree) {
              return (
                <div key={index} className={classNames(styles['time__item'], styles['time__item_disabled'])}>
                  <span dangerouslySetInnerHTML={{__html: moment.utc(item).format('HH:mm')}}/>
                </div>
              )
            }

            return (
              <button
                type={'button'}
                key={index}
                className={classes}
                onClick={this.onClickTimeHandler.bind(this, item)}
              >
                <span dangerouslySetInnerHTML={{__html: moment.utc(item).format('HH:mm')}}/>
              </button>
            )
          }) : (
            <div className={styles['time__empty']} dangerouslySetInnerHTML={{__html: 'Выберите дату'}}/>
          )}
        </div>
      </div>
    )
  }
}

Time.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Массив отображаемых времён
   */
  all: PropTypes.array.isRequired,
  /**
   * Массив заблокированных времён
   */
  free: PropTypes.array.isRequired
};

export default Time;
