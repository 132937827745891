import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconLock.less';

import {Icon} from 'Icons';

export default class IconLock extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-lock'], className);

    return (
      <Icon className={classes} style={style} viewBox={'0 0 12.3 16.3'}>
        <path fill="currentColor"
              d="M6 10.5h1v2h-1zM12.3 16.3h-12.3v-10.3h12.3v10.3zm-11.3-1h10.3v-8.3h-10.3v8.3zM10.3 7h-8.3v-2.8c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2v2.8zm-7.3-1h6.3v-1.8c0-1.8-1.4-3.2-3.1-3.2s-3.2 1.4-3.2 3.2v1.8z"/>
      </Icon>
    )
  }
}

IconLock.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
