import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Image.less';

class Image extends PureComponent {
  render() {
    const {className, style, alt, src} = this.props;
    const classes = classNames(styles['image'], className);

    return (
      <div className={classes} style={style}>
        <img className={styles['image__item']} src={src} alt={alt}/>
      </div>
    )
  }
}

Image.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Ресурсы изображения
   */
  src: PropTypes.string,
  /**
   * Подпись к изображению
   */
  alt: PropTypes.string
};

export default Image;
