import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconArrowRight.less';

import {Icon} from 'Icons';

export default class IconArrowRight extends PureComponent {
  render() {
    const {className, ...other} = this.props;
    const classes = classNames(styles['icon-arrow-right'], className);

    return (
      <Icon className={classes} {...other} viewBox={'0 0 14.7 11.2'}>
        <path fill="currentColor" d="M8.7 11.2l-1.4-1.5 4.5-3.9-4.5-4.4 1.4-1.4 6 5.8zm-8.7-6.5h13v2h-13z"/>
      </Icon>
    )
  }
}

IconArrowRight.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
