import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';

import classNames from 'classnames';
import styles     from './Footer.less';

import {FooterMenu, Subscribe} from 'Components';

class Footer extends PureComponent {
  render() {
    const {className, style, pathname} = this.props;
    const classes = classNames(styles['footer'], className);

    return (
      <div className={classes} style={style}>
        <div className={styles['footer__wrapper']}>
          <div className={styles['footer__inner']}>
            <div className={styles['footer__menu']}>
              <FooterMenu pathname={pathname}/>
            </div>
            <div className={styles['footer__subscribe']}>
              <Subscribe/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string
};

export default hot(module)(Footer);
