exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footer-menu--2-E4M {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n@media (min-width: 640px) {\n  .footer-menu--2-E4M {\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-box-align: baseline;\n    -webkit-align-items: baseline;\n        -ms-flex-align: baseline;\n            align-items: baseline;\n    -webkit-box-pack: justify;\n    -webkit-justify-content: space-between;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n  }\n}\n@media (min-width: 1280px) {\n  .footer-menu--2-E4M {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n  }\n}\n.footer-menu__item_copyright--fz0dK {\n  padding: 6px 0 0;\n}\n@media (min-width: 1280px) {\n  .footer-menu__item_copyright--fz0dK {\n    padding: 26px 0 0;\n  }\n}\n.footer-menu--2-E4M svg {\n  margin: -6px 0 0 0;\n  width: 60px;\n  height: 25px;\n}\n", ""]);

// exports
exports.locals = {
	"footer-menu": "footer-menu--2-E4M",
	"footer-menu__item_copyright": "footer-menu__item_copyright--fz0dK"
};