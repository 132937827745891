export default [
  {
    name: 'Управление многоквартирным домом',
    value: '15.30',
    
  },
  {
    name: 'Содержание общего имущества в&nbsp;многоквартирном доме',
    value: '9.01',
  },
  {
    name: 'Текущий ремонт общего имущества в&nbsp;многоквартирном доме',
    value: '4.08',
  },
  {
    name: 'Содержание придомовой территории',
    value: '3.16',
  },
  {
    name: 'Уборка МОП',
    value: '3.16',
  },
  {
    name: 'Вывоз мусора',
    value: '5.02',
  },
  {
    name: 'Услуги АДС',
    value: '3.72',
  },
  {
    name: 'Обслуживание домофонов',
    value: '1.50',
  },
  {
    name: 'Обслуживание систем АППЗ',
    value: '1.61',
  },
  {
    name: 'Обслуживание лифтов',
    value: '2.50',
  },
  {
    name: 'ХВС&nbsp;ОДН (по&nbsp;показаниям ОДПУ)',
    value: '30.60',
    units: '/ м³',
    star: true,
  },
  {
    name: 'ГВС&nbsp;ОДН (по&nbsp;показаниям ОДПУ)',
    value: '106.53',
    units: '/ м³',
    star: true,
  },
  {
    name: 'ЭЭ&nbsp;ОДН (день) (по&nbsp;показаниям ОДПУ)',
    value: '3.67',
    units: '/ кВт',
    star: true,
  },
  {
    name: 'ЭЭ&nbsp;ОДН (ночь) (по&nbsp;показаниям ОДПУ)',
    value: '2.13',
    units: '/ кВт',
    star: true,
  },
  {
    name: 'Уборка паркинга',
    value: '250',
    units: 'за место',
    note: 'для собственников паркинга',
  },
  {
    name: 'Охрана паркинга',
    value: '700',
    units: 'за место',
    note: 'для собственников паркинга',
  },
]