import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconArrow2Left.less';

import {Icon} from 'Icons';

export default class IconArrow2Left extends PureComponent {
  render() {
    const {className, ...other} = this.props;
    const classes = classNames(styles['icon-arrow-2-left'], className);

    return (
      <Icon className={classes} {...other} viewBox={'0 0 6.4 8.9'}>
        <path fill="currentColor" d="M5.1 8.9l-5.1-4.4 5.1-4.5 1.3 1.5-3.4 3 3.4 2.9z"/>
      </Icon>
    )
  }
}

IconArrow2Left.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
