import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconClose.less';

import {Icon} from '../Icons';

export default class IconClose extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-close'], className);

    return (
      <Icon className={classes} style={style} viewBox={'0 0 15 15'}>
        <path fill="currentColor" d="M12.9 15l-5.4-5.4-5.4 5.4-2.2-2.1 5.4-5.4-5.4-5.4 2.2-2.2 5.4 5.4 5.4-5.4 2.1 2.2-5.4 5.4 5.4 5.4z"/>
      </Icon>
    )
  }
}

IconClose.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
