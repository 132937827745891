export default {
  text: {
    onSubmit: {
      case: value => /^.+$/.test(value),
      message: name => `Заполните поле ${name}`
    }
  },
  name: {
    onBlur: {
      case: value => /^[А-ЯЁа-яёa-zA-Z\s]{2,}$/.test(value),
      message: name =>
        `В&nbsp;поле допустимы русские и&nbsp;латинские буквы и&nbsp;пробелы`
    },
    onSubmit: {
      case: value => /^[А-ЯЁа-яёa-zA-Z\s]{2,}$/.test(value),
      message: name =>
        `В&nbsp;поле допустимы русские и&nbsp;латинские буквы и&nbsp;пробелы`
    }
  },
  email: {
    onChange: [
      {
        case: value => !/^[а-яё]+$/i.test(value),
        message: name => "Смените язык"
      }
    ],
    onBlur: [
      {
        case: value =>
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(
            value
          ),
        message: name => "Укажите почту в&nbsp;формате info@aag.company"
      }
    ],
    onSubmit: [
      {
        case: value =>
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(
            value
          ),
        message: name => "Укажите почту в&nbsp;формате info@aag.company"
      }
    ]
  },
  phone: {
    onBlur: {
      case: value => /^\+7[0-9]{10}$/.test(value),
      message: name =>
        'Введите телефон в&nbsp;формате <span class="nowrap">+78126406046</span>'
    },
    onSubmit: {
      case: value => /^\+7[0-9]{10}$/.test(value),
      message: name =>
        'Введите телефон в&nbsp;формате <span class="nowrap">+78126406046</span>'
    }
  },
  password: {
    onSubmit: {
      case: value => /^.+$/.test(value),
      message: name => `Заполните поле ${name}`
    }
  },
  numbers: "",
  price: "",
  code: {
    onSubmit: {
      case: value => /^(\d){6}$/.test(value),
      message: name => "Заполните код"
    }
  },
  date: {
    onSubmit: {
      case: value => {
        return /^(\d){2}\.(\d){2}\.(\d){4}$/.test(value);
      },
      message: name => "Заполните дату"
    }
  },
  time: {
    onSubmit: {
      case: value => /^(\d){2}:(\d){2}$/.test(value),
      message: name => "Заполните время"
    }
  }
};
