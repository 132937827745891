import React, {Component} from 'react';
import PropTypes          from 'prop-types';

import classNames from 'classnames';
import styles     from './Aside.less';

class Aside extends Component {
  render() {
    const {className, style, TopBlock, BottomBlock, color, bg} = this.props;
    const classes = classNames(
      styles['aside'],
      color === 'black' ? styles['aside_black'] : null,
      color === 'white' ? styles['aside_white'] : null,
      color === 'gray' ? styles['aside_gray'] : null,
      bg === 'transparent' ? styles['aside_bg_transparent'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {TopBlock && (
          <div className={styles['aside__top']}>
            <TopBlock className={styles['aside__item']}/>
          </div>
        )}
        <div className={styles['aside__line']}/>
        {BottomBlock && (
          <div className={styles['aside__bottom']}>
            <BottomBlock className={styles['aside__item']}/>
          </div>
        )}
      </div>
    )
  }
}

Aside.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.string,
  /**
   * Цвет текста и линии
   */
  color: PropTypes.oneOf(['black', 'white', 'gray']),
  /**
   * Цвет фона
   */
  bg: PropTypes.oneOf(['transparent']),
  /**
   * Элемент, который отрисуетс внутри верхнего блока
   * (ожидается `ReactElement` или функция возвращающая его)
   */
  TopBlock: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Элемент, который отрисуетс внутри нижнего блока
   * (ожидается `ReactElement` или функция возвращающая его)
   */
  BottomBlock: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default Aside;
