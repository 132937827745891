exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".link__note--1DN0g {\n  color: gray;\n  font-size: 16px;\n  display: inline-block;\n  vertical-align: middle;\n  padding-left: 10px;\n}\n.tariff-link--2MO1Y svg {\n  color: #f44336;\n}\n.gray-text--3wTWy {\n  font-size: 13px;\n  font-weight: 300;\n  line-height: 1.54;\n  margin-bottom: 0;\n  color: gray;\n}\n.through-text--3Y68b {\n  text-decoration: line-through;\n  color: gray;\n  white-space: nowrap;\n}\n.bold-text--2seVM {\n  font-weight: bold;\n  white-space: nowrap;\n}\n.bold--2Y3SJ {\n  font-weight: bold;\n}\n.intro-block--2tPgd {\n  color: white;\n  padding: 70px 50px;\n  background-color: #142444;\n  margin-bottom: 50px;\n}\n@media only screen and (max-width: 600px) {\n  .intro-block--2tPgd {\n    padding: 40px 22px;\n    margin: 0 -22px 35px -22px;\n  }\n}\n.privacy--1wP4M {\n  margin: 15px 0 0;\n  font-size: 14px;\n  font-weight: 300;\n  line-height: 1.35714286;\n}\n", ""]);

// exports
exports.locals = {
	"link__note": "link__note--1DN0g",
	"tariff-link": "tariff-link--2MO1Y",
	"gray-text": "gray-text--3wTWy",
	"through-text": "through-text--3Y68b",
	"bold-text": "bold-text--2seVM",
	"bold": "bold--2Y3SJ",
	"intro-block": "intro-block--2tPgd",
	"privacy": "privacy--1wP4M"
};