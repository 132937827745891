exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confirm-phone--63eOs {\n  width: 100%;\n}\n.confirm-phone__info--16avf {\n  margin: 0 0 25px;\n}\n.confirm-phone__inner--3mUo1 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-flex-wrap: wrap;\n      -ms-flex-wrap: wrap;\n          flex-wrap: wrap;\n  margin: 22px 0 0;\n}\n.confirm-phone__text--1xl9t,\n.confirm-phone__timer--1Xw9x {\n  font-size: 14px;\n  font-weight: 300;\n  line-height: 1.71428571;\n  color: rgba(20, 36, 68, 0.7);\n}\n.confirm-phone__text--1xl9t {\n  margin: 0 5px 0 0;\n}\n.confirm-phone__timer--1Xw9x {\n  width: 32px;\n  margin: 0 20px 0 0;\n}\n.confirm-phone__send--2sWSq {\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  margin: 0 0 0 auto;\n  padding: 0;\n  border: none;\n  background-color: transparent;\n  color: #f44336;\n  font-size: 14px;\n  font-weight: 300;\n  line-height: 1.71428571;\n  cursor: pointer;\n  pointer-events: none;\n  opacity: 0;\n  -webkit-transform: translateX(5px);\n      -ms-transform: translateX(5px);\n          transform: translateX(5px);\n  -webkit-transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;\n  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;\n  -o-transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;\n  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;\n  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;\n}\n.confirm-phone__send--2sWSq:hover {\n  color: #f3665d;\n}\n.confirm-phone__send--2sWSq:focus {\n  outline: none;\n}\n.confirm-phone_state_resend--255nl .confirm-phone__send--2sWSq {\n  pointer-events: all;\n  opacity: 1;\n  -webkit-transform: translateX(0);\n      -ms-transform: translateX(0);\n          transform: translateX(0);\n}\n", ""]);

// exports
exports.locals = {
	"confirm-phone": "confirm-phone--63eOs",
	"confirm-phone__info": "confirm-phone__info--16avf",
	"confirm-phone__inner": "confirm-phone__inner--3mUo1",
	"confirm-phone__text": "confirm-phone__text--1xl9t",
	"confirm-phone__timer": "confirm-phone__timer--1Xw9x",
	"confirm-phone__send": "confirm-phone__send--2sWSq",
	"confirm-phone_state_resend": "confirm-phone_state_resend--255nl"
};