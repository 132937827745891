/**
 * Запрос за данными страницы
 * @param {string} name - название (тип) страницы
 * @param {string} url - адрес страницы
 * @param {object} config - параметры запроса
 * @return {Promise} - результат запроса за данными страницы
 */
export function fetchPageContent(name, url, config) {
  return Promise.resolve()
}

/**
 * Запрос за данными блока
 * @param {string} name - название (тип) блока
 * @param {object} config - параметры запроса
 * @return {Promise} - результат запроса за данными блока
 */
export function fetchBlockContent(name, config) {
  return Promise.resolve()
}
