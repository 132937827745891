import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {hot}              from 'react-hot-loader';
import {connect}          from 'react-redux';

import classNames from 'classnames';
import styles     from './PublicLayout.less';

import {Route, Switch}               from 'react-router';
import {Header, Footer, Aside, Menu} from 'Components';
import {Link}                        from "../../elements";
import { PHONE } from '../../utils/constants';

class PublicLayout extends Component {
  render() {
    const {className, routes, pathname, style} = this.props;
    const isMainPage = pathname === '/';
    const classes = classNames(styles['public-layout'], isMainPage ? styles['public-layout_main'] : null, className);

    return (
      <div className={classes} style={style}>
        <Header
          className={styles['public-layout__header']}
          pathname={pathname}
          pathnameToMain={'/'}
          AsideLeft={props => (
            <Aside
              color={'white'}
              TopBlock={props => <Link to={'/service'} text={'A-Service'} color={'white'} weight={'bold'} {...props}/>}
              BottomBlock={props => <Link to={'/'} text={'На главную'} color={'white'} weight={'bold'} {...props}/>}
              {...props}
            />
          )}
          AsideRight={props => (
            <Aside
              color={'white'}
              TopBlock={props => <Link
                text={PHONE}
                href={`tel:${PHONE}`}
                color={'white'} {...props}
              />}
              BottomBlock={props => <Link
                href={'https://aag.company/'}
                text={'О компании'}
                target={'_blank'}
                color={'white'} weight={'bold'} {...props}/>
              }
              {...props}
            />
          )}
        />
        <Switch>
          {routes && routes.map((route, i) => {
            const {Component, ...props} = route;
            return (
              <Route key={'page-' + i} {...props} render={() => (
                <Component {...props} pathname={pathname} className={classNames(styles['public-layout__inner'], 'page')}/>
              )}/>
            )
          })}
        </Switch>
        {!isMainPage && <Footer className={styles['public-layout__footer']} pathname={pathname}/>}
      </div>
    )
  }
}

PublicLayout.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string,
  /**
   * Массив роутеров уровня
   */
  routers: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Путь сопоставления роутера
     */
    path: PropTypes.string,
    /**
     * Путь должен полностью совпадать
     */
    exact: PropTypes.bool,
    /**
     * Ожидается `ReactElement` или функция возвращающая его
     */
    Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  }))
};

const mapStateToProps = (state) => {
  return {
    pathname: state.router.location.pathname
  }
};

export default hot(module)(connect(mapStateToProps)(PublicLayout));
