import {combineReducers} from 'redux';

import booking from './booking/booking.reducer';
import blocks  from './content/blocks.reducer';
import pages   from './content/pages.reducer';
import user    from './user/user.reducer';

export default combineReducers({
  blocks,
  booking,
  pages,
  user
})
