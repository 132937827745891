exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".image--34vB5 {\n  display: block;\n  line-height: 0;\n  border-bottom: 3px solid #f44336;\n}\n.image__item--20jCS {\n  max-width: 100%;\n  max-height: 100%;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"image": "image--34vB5",
	"image__item": "image__item--20jCS"
};