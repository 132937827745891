import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";
import styles from "./Bookings.less";

import moment from "moment";

import Helmet from "react-helmet";
import { IconClose, IconCheck, IconArrowRight } from "Icons";
import {
  Block,
  BlockBox,
  BlockField,
  FormBox,
  FormField,
  FormAction,
  Button,
  Modal,
  Link
} from "Elements";
import { BookingForm } from "Components";

import { cancelToBookObjects } from "../../store/booking/booking.actions";
import { getUserData } from "../../store/user/user.actions";
import document from "Assets/Договор_управления.pdf";
import document2 from "Assets/Бланк_голосования.pdf";
import document3 from "Assets/Приемка_квартиры_и_регистрации_права_собственности.pdf";
import document4 from "Assets/Собрание_собственников_19_04_2019.pdf";
import document5 from "Assets/Требования_к_вывескам_на_фасаде.pdf";
import document6 from "Assets/Устав_управляющей_компании_A-Service.pdf";

class Bookings extends PureComponent {
  constructor(props) {
    super(props);

    this.modal = React.createRef();
    this.onSuccesModal = {};
    this.booking = React.createRef();

    this.onClickCancelBooking = this.onClickCancelBooking.bind(this);
    this.getSuccessModal = this.getSuccessModal.bind(this);
    this.state = {
      successModalText: window.localStorage.getItem("openSuccessModal") || ""
    };
  }
  onClickCancelBooking() {
    const { booking, token } = this.props.user;

    return this.props
      .cancelToBookObjects(booking[0].id, token)
      .then(() => {
        return this.props.getUserData(token);
      })
      .then(() => {
        this.modal.current.closeModal();
      });
  }
  getSuccessModal(node) {
    this.onSuccesModal = node;
    if (this.state.successModalText && node) {
      window.localStorage.removeItem("openSuccessModal");
      this.onSuccesModal.openModal();
    }
  }
  render() {
    const { className, style, user } = this.props;
    const classes = classNames(styles["bookings"], className);
    const { isCancelReserveRequest } = this.props.booking;
    const { booking, objects } = user;
    let bookings = [];

    booking.map(item => {
      let current = {};

      objects.map(object => {
        if ("" + object.id === "" + item.objectId) {
          current = object;
        }
      });

      bookings.push({
        id: item.id,
        key: current.id,
        date: moment.utc(item.date).format("DD.MM"),
        time: moment.utc(item.time).format("HH:mm"),
        object: {
          type: current.type,
          value: current.number
        }
      });
    });

    return (
      <div className={classes} style={style}>
        {!bookings.length ? (
          <React.Fragment>
            <h1>Получайте ключи в&nbsp;ЖК&nbsp;&laquo;Ренессанс&raquo;</h1>
            <Helmet>
              <title>Получайте ключи в ЖК «Ренессанс»</title>
            </Helmet>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1>
              Вы&nbsp;записаны{" "}
              <span className={"nowrap"}>на&nbsp;получение ключей</span>
            </h1>
            <Helmet>
              <title>Вы записаны на получение ключей</title>
            </Helmet>
          </React.Fragment>
        )}

        {bookings.length ? (
          bookings.map((booking, id) => (
            <Block key={booking.key}>
              <FormBox>
                <FormField size={"l"} count={"single"} withAction={true}>
                  <BlockBox>
                    <BlockField count={"triple"}>
                      <div
                        className={styles["bookings__value"]}
                        dangerouslySetInnerHTML={{ __html: booking.date }}
                      />
                      <div
                        className={styles["bookings__title"]}
                        dangerouslySetInnerHTML={{ __html: "Дата" }}
                      />
                    </BlockField>
                    <BlockField count={"triple"}>
                      <div
                        className={styles["bookings__value"]}
                        dangerouslySetInnerHTML={{ __html: booking.time }}
                      />
                      <div
                        className={styles["bookings__title"]}
                        dangerouslySetInnerHTML={{ __html: "Время" }}
                      />
                    </BlockField>
                    <BlockField count={"triple"}>
                      <div
                        className={styles["bookings__value"]}
                        dangerouslySetInnerHTML={{
                          __html: booking.object.value
                        }}
                      />
                      <div
                        className={styles["bookings__title"]}
                        dangerouslySetInnerHTML={{
                          __html: booking.object.type
                        }}
                      />
                    </BlockField>
                  </BlockBox>
                </FormField>
                <FormAction size={"l"} inBlock={true}>
                  <Button
                    color={"red"}
                    size={"l"}
                    type={"button"}
                    text={"Отменить"}
                    hiddenTextOnDesktop={true}
                    IconRight={props => <IconClose {...props} />}
                    onClick={() => this.modal.current.openModal()}
                  />
                  <div
                    className={classNames(
                      styles["bookings__title"],
                      styles["bookings__title_cancel"]
                    )}
                    dangerouslySetInnerHTML={{ __html: "Отменить" }}
                  />
                </FormAction>
              </FormBox>
            </Block>
          ))
        ) : (
          <div className={styles["bookings__more"]}>
            <Link
              color={"blue"}
              weight={"bold"}
              text={"Записаться на&nbsp;получение ключей"}
              IconRight={props => <IconArrowRight {...props}/>}
              href={"tel:+78122422222"}
            />
          </div>
        )}

        <br />
        <div className={styles["bookings__more"]}>
          <Link
            color={"blue"}
            weight={"bold"}
            text={
              "Приемка квартиры и&nbsp;регистрация<br/> права собственности"
            }
            href={document3}
            download={true}
          />
          <span className={styles["bookings__download-note"]}>
            {" "}
            (.pdf, 0.2 мб)
          </span>
        </div>
        <br />
        <div className={styles["bookings__more"]}>
          <Link
            color={"gray"}
            weight={"bold"}
            text={"Договор управления"}
            disabled={true}
          />
          <span className={styles["bookings__download-note"]}>
            {" "}
            (появится позже)
          </span>
        </div>
        <br />
        <div className={styles["bookings__more"]}>
          <Link
            color={"blue"}
            weight={"bold"}
            text={"Устав управляющей компании A-Service"}
            href={document6}
            download={true}
          />
          <span className={styles["bookings__download-note"]}>
            {" "}
            (.pdf, 18.3 мб)
          </span>
        </div>
        <br />

        <Modal label={"Уверены, что хотите отменить бронь?"} ref={this.modal}>
          <div className={styles["booking-cancel"]}>
            <p>На&nbsp;текущий момент можно отменить только все брони.</p>
            <FormBox>
              <FormField size={"l"} count={"double"}>
                <Button
                  type={"button"}
                  color={"gray"}
                  size={"l"}
                  text={"Не отменять"}
                  IconLeft={props => <IconClose {...props} />}
                  onClick={() => this.modal.current.closeModal()}
                />
              </FormField>
              <FormField size={"l"} count={"double"}>
                <Button
                  type={"button"}
                  color={"red"}
                  size={"l"}
                  loading={isCancelReserveRequest}
                  text={"Отменить запись"}
                  IconLeft={props => <IconCheck {...props} />}
                  onClick={this.onClickCancelBooking}
                />
              </FormField>
            </FormBox>
          </div>
        </Modal>

        <Modal
          label={"Вы записаны на получение ключей"}
          ref={this.getSuccessModal}
        >
          <p
            dangerouslySetInnerHTML={{ __html: this.state.successModalText }}
          />
          <div className={styles["booking-cancel"]}>
            <FormBox>
              <FormField size={"l"} count={"double"}>
                <Button
                  type={"button"}
                  color={"red"}
                  size={"l"}
                  text={"Ок"}
                  onClick={() => {
                    this.onSuccesModal.closeModal();
                    this.setState({ successModalText: "" });
                  }}
                  onClose={() => {
                    this.setState({ successModalText: "" });
                  }}
                />
              </FormField>
            </FormBox>
          </div>
        </Modal>

        <Modal label={"Записаться на&nbsp;получение ключей"} ref={this.booking}>
          <BookingForm
            onBooking={message => {
              this.setState(
                {
                  successModalText: message
                },
                e => {
                  this.booking.current.closeModal();
                  this.onSuccesModal.openModal();
                }
              );
            }}
          />
        </Modal>
      </div>
    );
  }
}

Bookings.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {
    booking: state.booking,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelToBookObjects: (id, token) => {
      return dispatch(cancelToBookObjects(id, token));
    },
    getUserData: id => {
      return dispatch(getUserData(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bookings);
