import React from 'react';

import {Redirect}                                                                       from 'react-router-dom';
import {Application, ServiceLayout, PublicLayout}                                       from 'Layouts';
import {BookingsPage, MainPage, NotFoundPage, PrivacyPage, ProfilePage, RegulationPage, WelcomePage, RatesPage } from 'Pages';

export default {
  // уровень приложения
  path: '/',
  Component: Application,
  routes: [
    {
      path: '/',
      Component: ServiceLayout,
      routes: [
        // уровень страницы
        {
          path: '/',
          exact: true,
          status: 301,
          Component: () => <Redirect to={'/welcome'}/>
        },
        {
          path: '/service',
          exact: true,
          status: 301,
          Component: () => <Redirect to={'/welcome'}/>
        },
        {
          path: '/regulation',
          exact: true,
          Component: RegulationPage,
        },
        {
          path: '/rates',
          exact: true,
          Component: RatesPage,
        },
        {
          path: '/welcome',
          exact: true,
          Component: WelcomePage,
        },
        {
          path: '/bookings',
          exact: true,
          Component: BookingsPage,
        },
        {
          path: '/objects',
          exact: true,
          Component: ProfilePage,
        },
        {
          path: '/privacy',
          exact: true,
          Component: PrivacyPage,
        },
        {
          path: '*',
          status: 404,
          Component: NotFoundPage,
        }
      ]
    }
  ]
}
