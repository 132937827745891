import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {connect}          from 'react-redux';

import classNames from 'classnames';
import styles     from './Menu.less';

import * as RouterDOM from 'react-router-dom';

import Icon             from './__static__/Icon.svg';
import {IconArrow2Left} from 'Icons';


import {signOut} from "../../store/user/user.actions";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.onClickSignOut = this.onClickSignOut.bind(this);
  }

  onClickSignOut() {
    this.props.signOut();
    if (this.props.onSignOut) {
      this.props.onSignOut();
    }
  }

  render() {
    const {className, style, items, pathname} = this.props;
    const classes = classNames(styles['menu'], className);

    return (
      <div className={classes} style={style}>
        {items.map((item, index) => {
          if (item.link === pathname) {
            return (
              <div key={index} className={classNames(styles['menu__item'], styles['menu__item_active'])}>
                <div className={styles['menu__inner']}>
                  <div className={styles['menu__title']}>
                    {item.icon && (
                      <div className={styles['menu__icon']}>
                        <Icon className={styles['menu__image']}/>
                      </div>
                    )}
                    <div className={styles['menu__text']} dangerouslySetInnerHTML={{__html: item.title}}/>
                  </div>
                  {item.desc && (
                    <div className={styles['menu__desc']} dangerouslySetInnerHTML={{__html: item.desc}}/>
                  )}
                </div>
                {item.link === '/objects' && (
                  <button className={styles['menu__sign-out']} onClick={() => {
                    this.props.signOut();
                    if (this.props.onSignOut) {
                      this.props.onSignOut();
                    }
                  }}>
                    <span className={styles['menu__icon']}>
                      <IconArrow2Left/>
                    </span>
                    <span>Выйти</span>
                  </button>
                )}
              </div>
            )
          }

          return (
            <div key={index} className={styles['menu__item']}>
              <RouterDOM.Link to={item.link} className={styles['menu__inner']}>
                <div className={styles['menu__title']}>
                  {item.icon && (
                    <div className={styles['menu__icon']}>
                      <Icon className={styles['menu__image']}/>
                    </div>
                  )}
                  <div className={styles['menu__text']} dangerouslySetInnerHTML={{__html: item.title}}/>
                </div>
                {item.desc && (
                  <div className={styles['menu__desc']} dangerouslySetInnerHTML={{__html: item.desc}}/>
                )}
              </RouterDOM.Link>
              {item.link === '/objects' && (
                <button className={styles['menu__sign-out']} onClick={this.onClickSignOut}>
                  <span className={styles['menu__icon']}>
                    <IconArrow2Left/>
                  </span>
                  <span>Выйти</span>
                </button>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

Menu.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.string,
  /**
   * Массив элементов меню
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Адрес страницы
     */
    link: PropTypes.string.isRequired,
    /**
     * Заголовок ссылки
     */
    title: PropTypes.string.isRequired,
    /**
     * Отображать иконку рядом с названием
     */
    icon: PropTypes.bool,
    /**
     * Описание к полю
     */
    desc: PropTypes.string,
  })).isRequired,
  /**
   * Текущий url страницы
   */
  pathname: PropTypes.string.isRequired,
  /**
   * Метод выйти
   */
  singOut: PropTypes.func,
  /**
   * Обработчик события выхода
   */
  onSignOut: PropTypes.func
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      return dispatch(signOut());
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
