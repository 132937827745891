import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';
import {connect}              from 'react-redux';

import classNames from 'classnames';
import styles     from './Application.less';

import ReactGA                from 'react-ga';
import {YMInitializer}        from 'react-yandex-metrika'

import {Route, Switch} from 'react-router';
import Helmet          from 'react-helmet';

!__IS_DEV__ && ReactGA.initialize('UA-128417229-1');

import favicon        from 'Assets/favicon.ico';
import faviconSmall   from 'Assets/favicon-16x16.png';
import faviconNormal  from 'Assets/favicon-32x32.png';
import faviconBig     from 'Assets/favicon-96x96.png';
import imageOpenGraph from 'Assets/opengraph.jpg';
import imageTwitter   from 'Assets/twitter.jpg';

class Application extends PureComponent {
  render() {
    const {routes, pathname} = this.props;
    const isMainPage = pathname === '/';
    const classes = classNames(styles['application'], isMainPage ? styles['application_main'] : null);

    return (
      <div className={classes}>
        {!__IS_DEV__ && <YMInitializer accounts={[50947286]}/>}
        {!__IS_DEV__ && (
          <div dangerouslySetInnerHTML={{ __html: `
            <!-- Global site tag (gtag.js) - Google Analytics -->
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-128417229-1"></script>
            <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'UA-128417229-1');
            </script>
          `}}></div>
        )}
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0"/>
          <meta name="format-detection" content="telephone=no"/>
          <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=9;IE=10;IE=Edge,chrome=1"/>

          <title>AAG. Сервис управление квартирой</title>
          <meta name="description" content="AAG. Сервис управление квартирой для жильцов и собственников. Оплата квитанций, показания счетчиков, прием заявок и обращений. Новости, онлайн-собрания и голосования."/>

          <meta property="og:title" content="Инвестиционно строительный холдинг AAG"/>
          <meta property="og:description" content="AAG. Сервис управление квартирой для жильцов и собственников. Оплата квитанций, показания счетчиков, прием заявок и обращений. Новости, онлайн-собрания и голосования."/>
          <meta property="og:image" content={imageOpenGraph}/>

          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:title" content="Инвестиционно строительный холдинг AAG"/>
          <meta name="twitter:description" content="AAG. Сервис управление квартирой для жильцов и собственников. Оплата квитанций, показания счетчиков, прием заявок и обращений. Новости, онлайн-собрания и голосования."/>
          <meta name="twitter:image" content={imageTwitter}/>

          <link rel="icon" href={favicon} type="image/x-icon"/>
          <link rel="shortcut icon" href={favicon} type="image/x-icon"/>
          <link rel="icon" type="image/png" sizes="16x16" href={faviconSmall}/>
          <link rel="icon" type="image/png" sizes="32x32" href={faviconNormal}/>
          <link rel="icon" type="image/png" sizes="96x96" href={faviconBig}/>

          <meta name="yandex-verification" content=""/>
          <meta name="google-site-verification" content=""/>
          <meta name="cmsmagazine" content="6b918355ad4ec31e713e8eefe2a5b74c"/>
        </Helmet>

        <Switch>
          {routes && routes.map((route, i) => {
            const {Component, ...props} = route;

            return (
              <Route key={'layout-' + i} {...props} render={() => (
                <Component {...props} className={styles['application__inner']} pathname={pathname}/>
              )}/>
            )
          })}
        </Switch>
      </div>
    )
  }
}

Application.propTypes = {
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string,
  /**
   * Массив роутеров приложения
   */
  routers: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Путь сопоставления роутера
     */
    path: PropTypes.string,
    /**
     * Путь должен полностью совпадать
     */
    exact: PropTypes.bool,
    /**
     * Ожидается `ReactElement` или функция возвращающая его
     */
    Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  }))
};

const mapStateToProps = (state) => {
  return {
    pathname: state.router.location.pathname
  }
};

export default hot(module)(connect(mapStateToProps)(Application));
