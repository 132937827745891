exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon-check--nv624 {\n  width: 16px;\n  height: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"icon-check": "icon-check--nv624"
};