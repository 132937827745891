import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {hot}              from 'react-hot-loader';
import {connect}          from 'react-redux';

import classNames from 'classnames';
import styles     from './ServiceLayout.less';

import Helmet          from 'react-helmet'
import {Route, Switch} from 'react-router';

import {getUserData} from '../../store/user/user.actions';

import { Link } from 'Elements';
import { Header, Footer, SignIn, Menu, Aside, Authentication } from 'Components';
import { PHONE } from '../../utils/constants';

const menu = [
  {
    title: 'Квартира в&nbsp;ЖК&nbsp;«Ренессанс»',
    link: '/welcome',
  },
  {
    title: 'Запись на&nbsp;получение ключей',
    link: '/bookings'
  },
  {
    title: 'Регламент передачи квартиры',
    link: '/regulation'
  },
  {
    title: 'Тарифы',
    link: '/rates'
  },
  {
    title: 'Мои объекты',
    link: '/objects'
  }
];

const noAuthPages = [
  '/privacy',
]

class ServiceLayout extends Component {
  componentDidMount() {
    if (this.props.user.token) {
      this.props.getUserData(this.props.user.token)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.token && (prevProps.user.token !== this.props.user.token)) {
      this.props.getUserData(this.props.user.token)
    }
  }

  render() {
    const {className, style, routes, pathname, user} = this.props;
    const classes = classNames(
      styles['service-layout'],
      className
    );
    const isNoAuthPage = noAuthPages.includes(pathname);

    return (
      <div className={classes} style={style}>
        {user && !!user.token ? (
          <React.Fragment>
            <Header
              className={styles['service-layout__header']}
              pathname={pathname}
              pathnameToMain={'/service'}
              showMenuOnDesktop={true}
              AsideLeft={props => (
                <Aside
                  color={'white'}
                  TopBlock={props => <Link href={'https://aag-service.ru/'} text={'На главную'} color={'white'} weight={'bold'} {...props}/>}
                  BottomBlock={props => <Link
                    text={PHONE}
                    href={`tel:${PHONE}`}
                    color={'white'} {...props}
                  />}
                  {...props}
                />
              )}
              Menu={props => (
                <Menu
                  items={menu}
                  {...props}
                />
              )}
            />
            {user.id && (
              <Switch>
                {routes && routes.map((route, i) => {
                  const {Component, ...props} = route;
                  return (
                    <Route key={'page-' + i} {...props} render={() => (
                      <Component {...props} pathname={pathname} className={classNames(styles['service-layout__inner'], 'page')}/>
                    )}/>
                  )
                })}
              </Switch>
            )}
            <div className={styles['service-layout__footer']}>
              <div className={styles['service-layout__items']}>
                <div className={styles['service-layout__item']}>
                  <Link
                    text={'&copy;&nbsp;2018&nbsp;AAG'}
                    weight={'thin'}
                    color={'gray'}
                    current={pathname}
                    to={'/'}
                  />
                </div>
                <div className={styles['service-layout__item']}>
                  <Link
                    text={'Политика конфиденцильности'}
                    weight={'thin'}
                    color={'gray'}
                    current={pathname}
                    to={'/privacy'}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Header
              className={styles['service-layout__header']}
              pathname={pathname}
              showMenuOnDesktop={true}
              AsideLeft={props => (
                <Aside
                  color={'white'}
                  TopBlock={props => <Link href={'https://aag-service.ru/'} text={'На главную'} color={'white'} weight={'bold'} {...props}/>}
                  BottomBlock={props => <Link
                    text={PHONE}
                    href={`tel:${PHONE}`}
                    color={'white'} {...props}
                  />}
                  {...props}
                />
              )}
            />
            {!isNoAuthPage && (
              <div className={classNames(styles['service-layout__inner'], 'page')}>
                <Helmet>
                  <title>Получайте ключи в&nbsp;ЖК&nbsp;&laquo;Ренессанс&raquo;</title>
                </Helmet>
                <h1>Получайте ключи в&nbsp;ЖК&nbsp;&laquo;Ренессанс&raquo;</h1>
                <div className={classNames(styles['service-layout__Authentication'])}>
                  <Authentication/>
                </div>
                <div className={classNames(styles['service-layout__contacts'])}>
                  <a
                    className={classNames(styles['service-layout__contacts-phone'])}
                    href={`tel:${PHONE}`}
                    children={PHONE}
                  />
                  <p
                    className={classNames(styles['service-layout__contacts-text'])}
                    dangerouslySetInnerHTML={{ __html: `Также вы можете записаться по телефону. <br/>
                    Мы работаем со вторника по субботу с 10:00 до 18:00.` }}
                  />
                </div>
              </div>
            )}
            <Switch>
              {routes && routes.map((route, i) => {
                const {Component, ...props} = route;

                return isNoAuthPage && (
                  <Route key={'page-' + i} {...props} render={() => (
                    <Component {...props} pathname={pathname} className={classNames(styles['service-layout__inner'], 'page')}/>
                  )}/>
                )
              })}
            </Switch>
          </React.Fragment>
        )}
      </div>
    )
  }
}

ServiceLayout.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string,
  /**
   * Массив роутеров уровня
   */
  routers: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Путь сопоставления роутера
     */
    path: PropTypes.string,
    /**
     * Путь должен полностью совпадать
     */
    exact: PropTypes.bool,
    /**
     * Ожидается `ReactElement` или функция возвращающая его
     */
    Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  }))
};

const mapStateToProps = (state) => {
  return {
    pathname: state.router.location.pathname,
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (token) => {
      return dispatch(getUserData(token))
    }
  }
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(ServiceLayout));
