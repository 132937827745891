exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon-arrow-2-left--1Hcgq {\n  width: 7px;\n  height: 9px;\n}\n", ""]);

// exports
exports.locals = {
	"icon-arrow-2-left": "icon-arrow-2-left--1Hcgq"
};