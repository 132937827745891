import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './BlockBox.less';

class BlockBox extends PureComponent {
  render() {
    const {className, style, children} = this.props;
    const classes = classNames(styles['block-box'], className);

    return (
      <div className={classes} style={style}>
        {children}
      </div>
    )
  }
}

BlockBox.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default BlockBox
