exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon_color_red--1EIyq {\n  color: #f3665d;\n}\n.icon_color_blue--THGAS {\n  color: #161c2a;\n}\n", ""]);

// exports
exports.locals = {
	"icon_color_red": "icon_color_red--1EIyq",
	"icon_color_blue": "icon_color_blue--THGAS"
};