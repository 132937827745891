import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import classNames         from 'classnames';
import {hot}              from "react-hot-loader";
import {connect}          from "react-redux";

import styles     from '../Bookings/Bookings.less';
import {Block, BlockBox, BlockField, FormBox, FormField, FormAction, Button, Modal, Link} from 'Elements';

export default class SuccessForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message, getRef, onClick, onClose } = this.props;

    return (
      <Modal label={'Вы записаны на получение ключей'} onClose={onClose} ref={getRef}>
        <p dangerouslySetInnerHTML={{ __html: message }} />
        <div className={styles['booking-cancel']}>
          <FormBox>
            <FormField size={'l'} count={'double'}>
              <Button
                type={'button'}
                color={'red'}
                size={'l'}
                text={'Ок'}
                onClick={() => {onClick()}}
              />
            </FormField>
          </FormBox>
        </div>
      </Modal>
    )
  }
}
