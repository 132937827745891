import {constants} from './booking.actions.js'

/**
 * Редьюсер хранилища бронирований
 * @param {object} state - текущее состояние
 * @param {object} action - текущее действие
 * @return {object} - новое состояние хранилища
 */
export default function (state = {dates: {}, times: {}}, action) {
  switch (action.type) {
    //case constants.GET_AVAILABLE_DATES_REQUEST:
    //case constants.GET_AVAILABLE_DATES_FAILURE:
    //case constants.GET_AVAILABLE_TIMES_REQUEST:
    //case constants.GET_AVAILABLE_TIMES_FAILURE:

    // запрос за доступными датами завершился успешно
    case constants.GET_AVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        dates: {
          from: action.from,
          to: action.to,
          free: action.free,
        }
      };

    // запрос за доступными временами завершился успешно
    case constants.GET_AVAILABLE_TIMES_SUCCESS:
      return {
        ...state,
        times: {
          all: action.all,
          free: action.free,
        }
      };

    // очистить доступные времена
    case constants.ClEAN_AVAILABLE_TIMES:
      return {
        ...state,
        times: {}
      };

    // пошел запрос на бронь
    case constants.RESERVE_REQUEST:
      return {
        ...state,
        isReserveRequest: true
      };

    // запрос на бронь завершился успешно
    case constants.RESERVE_SUCCESS:
      return {
        ...state,
        isReserveRequest: false
      };

    // запрос на бронь завершился ошибкой
    case constants.RESERVE_FAILURE:
      return {
        ...state,
        isReserveRequest: false
      };

    // пошел запрос на снятие брони
    case constants.CANCEL_RESERVE_REQUEST:
      return {
        ...state,
        isCancelReserveRequest: true
      };

    // запрос на снятие брони завершился успешно
    case constants.CANCEL_RESERVE_SUCCESS:
      return {
        ...state,
        isCancelReserveRequest: false
      };

    // запрос на снятие брони завершился ошибкой
    case constants.CANCEL_RESERVE_FAILURE:
      return {
        ...state,
        isCancelReserveRequest: false
      };

    default:
      return state;
  }
}
