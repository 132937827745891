exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".profile__value--3D8hi {\n  font-weight: 800;\n  letter-spacing: -1.9px;\n  font-size: 28px;\n  line-height: 1.28571429;\n}\n@media (min-width: 640px) {\n  .profile__value--3D8hi {\n    font-size: 40px;\n    line-height: 1.25;\n  }\n}\n@media (min-width: 1280px) {\n  .profile__value--3D8hi {\n    font-size: 50px;\n    line-height: 1.2;\n  }\n}\n@media (min-width: 1440px) {\n  .profile__value--3D8hi {\n    font-size: 70px;\n    line-height: 1.14285714;\n  }\n}\n.profile__title--3D5Ti {\n  font-size: 14px;\n  letter-spacing: 0.2px;\n  margin: 4px 0 0;\n}\n@media (min-width: 640px) {\n  .profile__title--3D5Ti {\n    font-size: 16px;\n  }\n}\n@media (min-width: 960px) {\n  .profile__title--3D5Ti {\n    font-size: 18px;\n    margin: 20px 0 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"profile__value": "profile__value--3D8hi",
	"profile__title": "profile__title--3D5Ti"
};