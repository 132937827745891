import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';

import classNames from 'classnames';
import styles     from './FooterMenu.less';

import {Link} from 'Elements';

class FooterMenu extends PureComponent {
  render() {
    const {className, style, pathname} = this.props;
    const classes = classNames(styles['footer-menu'], className);

    return (
      <div className={classes} style={style}>
        <div className={styles['footer-menu__item']}>
          <Link to={'/'} color={'gray'} weight={'thin'} current={pathname}>&copy;&nbsp;2018&nbsp;AAG</Link>
        </div>
        <div className={classNames(styles['footer-menu__item'], styles['footer-menu__item_copyright'])}>
          <Link href={'https://nimax.ru/'} color={'blue'} weight={'bold'} target={'_blank'}>
            Создание сайта&nbsp;—&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.9 84.9">
              <path fill="#00EF81" d="M186.9 34h-186.9l17-17-17-17h186.9l17 17z"/>
              <path fill="currentColor"
                    d="M5.2 84.9v-34h10.3l11.4 13.2v-13.2h12.9v34h-10.2l-11.4-13.2v13.2h-13zm57-34v34h-12.9v-34h12.9zm136.5 34l-12.3-17 12.3-17h-14.7l-5 7.4-5-7.4h-14.6l12.3 17-12.3 17h14.7l4.9-7.7 4.9 7.7h14.8zm-54.1 0h13.1l-12.8-34h-13.9l-12.8 34h13.1l1.2-3.9h11l1.1 3.9zm-9.5-12.6l2.8-9.2 2.8 9.2h-5.6zm-32.9-21.4l-9.5 15.7-9.5-15.7h-11.6v34h12.9v-13.6l4.4 7.3h7.5l4.4-7.3v13.6h12.9v-34h-11.5z"/>
            </svg>
          </Link>
        </div>
      </div>
    )
  }
}

FooterMenu.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string
};

export default hot(module)(FooterMenu);
