import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {connect}              from 'react-redux';

import classNames from 'classnames';
import styles     from './Subscribe.less';

import {IconArrow2Right}                                                      from 'Icons';
import {Form, FormBox, FormField, FormAction, Input, Button, FormError, Link} from 'Elements';

import {subscribe} from '../../store/user/user.actions'

class Subscribe extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      email: '',
      isLoading: false,
      isSubmitForm: false
    };

    this.form = React.createRef();
    this.email = React.createRef();

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(e, value) {
    this.setState({
      [e.target.name]: value
    });
  }

  onSubmitForm() {
    return this.form.current.validate()
      .then(() => {
        this.setState({
          isLoading: true,
          error: ''
        })
      })
      .then(() => {
        return this.props.subscribe(this.state.email)
          .then(() => {
            this.setState({
              isSubmitForm: true
            })
          })
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          })
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err)
      })
      .then(() => {
        this.setState({
          isLoading: false
        });
      })
  }


  render() {
    const {error, email, isSubmitForm} = this.state;
    const {className, style} = this.props;
    const classes = classNames(styles['subscribe'], isSubmitForm ? styles['subscribe_state_submit'] : null, className);

    return (
      <div className={classes} style={style}>
        <div className={styles['subscribe__inner']}>
          <div className={styles['subscribe__info']}>
            <h4>Подпишитесь на&nbsp;новости</h4>
            <p>
              Нажимая на&nbsp;кнопку, вы&nbsp;соглашаетесь <Link
              underline={true} color={'blue'} weight={'thin'} text={'с&nbsp;политикой конфиденциальности.'}
              to={'/privacy'}/>
            </p>
          </div>
          <div className={styles['subscribe__wrapper']}>
            <div className={styles['subscribe__message']}>Вы&nbsp;успешно подписались на&nbsp;наши новости</div>
            <div className={styles['subscribe__form']}>
              <Form onSubmit={this.onSubmitForm} ref={this.form} fields={[this.email]}>
                <FormBox>
                  <FormField size={'s'} count={"single"} withAction={true}>
                    <Input
                      type={'email'}
                      label={'Почта'}
                      value={email}
                      name={'email'}
                      id={'subscribe-email'}
                      size={'s'}
                      onChange={this.onChangeField}
                      ref={this.email}
                    />
                  </FormField>
                  <FormAction size={'s'}>
                    <Button
                      type={'submit'}
                      color={'red'}
                      size={'s'}
                      loading={this.props.user.isSubscribeRequest}
                      text={'Подписаться'}
                      IconRight={props => <IconArrow2Right {...props}/>} hiddenTextOnDesktop={true}
                    />
                  </FormAction>
                </FormBox>
                {error && (
                  <FormError error={error}/>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Subscribe.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    subscribe: (email) => {
      return dispatch(subscribe(email))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
