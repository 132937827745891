import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import fetch from "cross-fetch";

import classNames from "classnames";
import styles from "./WelcomePage.less";

import Helmet from "react-helmet";
import { Block, Link, Button } from "Elements";
import { IconClose, IconCheck, IconArrowRight } from "Icons";
import document from "Assets/Бланк_голосования.pdf";
import document2 from "Assets/Собрание_собственников_19_04_2019.pdf";
import { PHONE } from "../../utils/constants";

class RegulationPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      password: ""
    };
  }

  getPassword(phone) {
    return `https://api.lk.aag-service.ru/api/getpassword?phone=${phone}&token=$2y$10$YZ8DPLbRkSNLvvkmj.1DF.azMaBhdBTGiJ5L0Qgzanp0LD5sNlKUC`;
  }

  fetchPassword() {
    const { user } = this.props;
    const url = this.getPassword(user.phone.substring(1, 11));
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          this.setState({
            password: data.data.password
          });
        }
      });
  }

  componentDidUpdate() {
    if (!this.state.password) this.fetchPassword();
  }

  componentDidMount() {
    if (!this.state.password) this.fetchPassword();
  }

  render() {
    const { className, style, user } = this.props;
    const classes = classNames(styles["regulation-page"], className);
    const phone = user.phone.substring(1, 11);
    // const c = {
    //   [0]: 'M',
    //   [1]: 'T',
    //   [2]: 'w',
    //   [3]: 'L',
    //   [4]: 'P',
    //   [5]: 'u',
    //   [6]: 'g',
    //   [7]: 'b',
    //   [8]: 'X',
    //   [9]: 'E',
    // };

    // const password = c[phone[6]] + c[phone[4]] + c[phone[5]] + c[phone[9]] + c[phone[7]] + c[phone[8]];

    return (
      <div className={classes} style={style}>
        <Helmet>
          <title>Квартира в ЖК «Ренессанс»</title>
        </Helmet>

        <h1>
          Поздравляем с&nbsp;покупкой квартиры
          в&nbsp;ЖК&nbsp;&laquo;Ренессанс&raquo;!
        </h1>
        <p style={{ marginTop: "-20px" }}>
          Приветствуем вас в&nbsp;сообществе соседей! Здесь вы&nbsp;можете
          ознакомиться с&nbsp;подробной информацией о&nbsp;доме
          и&nbsp;управляющей компании. После передачи ключей вы&nbsp;получите
          доступ к&nbsp;чатам с&nbsp;другими жильцами и&nbsp;диспетчером,
          сможете оставлять заявки и&nbsp;оплачивать услуги.
        </p>
        <p>
          <strong>
            Рассылка приглашений на&nbsp;приёмку квартир начнётся
            15&nbsp;ноября.
          </strong>
        </p>
        <br />

        <h3>В&nbsp;первый год</h3>
        <p>
          После заселения жилого комплекса закладывается база для его дальнейшей
          благополучной эксплуатации. Этот период требует слаженной
          и&nbsp;оперативной работы специалистов по&nbsp;сервису
          и&nbsp;эксплуатации, обладающих навыками решения задач любой
          сложности. На&nbsp;этапе первого цикла эксплуатации
          &laquo;весна-лето-осень-зима&raquo; особое внимание решению сезонных
          задач. В&nbsp;это время застройщик, проектировщики и&nbsp;подрядчики
          передают управляющей компании необходимые знания и&nbsp;консультируют
          сотрудников.
        </p>
        <br />

        <h3>Управляющая компания</h3>
        <p>
          Застройщик доверил управление многоквартирным домом нашей
          организации&nbsp;&mdash; управляющая компания A-Service (ООО
          &laquo;Инновационные стандарты качества&raquo;).
        </p>
        <p>
          После получение разрешения на&nbsp;ввод дома в&nbsp;эксплуатацию вам
          будет отправлено уведомление о&nbsp;дате проведения общего собрания
          собственников. На&nbsp;нём предстоит выбрать управляющую компанию,
          определить размера квартплаты и&nbsp;другие вопросы, касающиеся
          жизнеобеспечения дома.
        </p>
        <p>
          Мы&nbsp;делаем ставку на&nbsp;диалог с&nbsp;жильцами и&nbsp;нацелены
          на&nbsp;поддержание высокого качества жизни, заложенного
          в&nbsp;проекте. Голосуя за&nbsp;ООО &laquo;Инновационные стандарты
          качества&raquo;, вы&nbsp;определяете будущее своего дома.
        </p>

        <br />
        <br />
      </div>
    );
  }
}

RegulationPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (token, config) => {
      return dispatch(updateProfile(token, config));
    }
  };
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegulationPage)
);
