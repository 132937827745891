import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Header.less';

import {Link} from 'Elements';
import Helmet from 'react-helmet';

import IconLogoDesktop from './__static__/IconLogoDesktop.svg';
import IconLogoMobile  from './__static__/IconLogoMobile.svg';

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isStateOpen: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  componentDidUpdate(props) {
    if (this.props.pathname !== props.pathname) {
      this.setState({
        isStateOpen: false
      })
    }
  }

  toggleMenu() {
    this.setState(state => {
      return {
        isStateOpen: !state.isStateOpen
      }
    })
  }

  hideMenu() {
    this.setState({
      isStateOpen: false
    })
  }

  render() {
    const {isStateOpen} = this.state;
    const {className, style, pathname, pathnameToMain, Menu, AsideLeft, AsideRight, showMenuOnDesktop} = this.props;
    const classes = classNames(
      styles['header'],
      isStateOpen ? styles['header_state_open'] : null,
      showMenuOnDesktop ? styles['header_show-menu_on-desktop'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {isStateOpen && (
          <Helmet>
            <body className={'body_state_show-menu'}/>
          </Helmet>
        )}
        <div className={styles['header__inner']}>
          <div className={styles['header__image']}>
            <Link to={pathnameToMain ? pathnameToMain : '/'} current={pathname}>
              <IconLogoDesktop className={classNames(styles['header__logo'], styles['header__logo_desktop'])}/>
              <IconLogoMobile className={classNames(styles['header__logo'], styles['header__logo_mobile'])}/>
            </Link>
          </div>
          <div className={styles['header__wrapper']}>

            {Menu && (
              <Menu className={classNames(styles['header__menu'])} pathname={pathname} onSignOut={this.hideMenu}/>
            )}


            {/*<div className={styles['header__info']}>*/}
            {/*  Новости ЖК&nbsp;и&nbsp;дома, чаты для жильцов, услуги и&nbsp;бонусы, голосования и&nbsp;результаты собраний, счетчики и&nbsp;оплата квитанций&nbsp;&mdash; в&nbsp;личном кабинете A-Service.*/}
            {/*</div>*/}

            {AsideLeft && (
              <AsideLeft className={classNames(styles['header__aside'], styles['header__aside_left'])}/>
            )}

            {AsideRight && (
              <AsideRight className={classNames(styles['header__aside'], styles['header__aside_right'])}/>
            )}

            <div className={styles['header__navigation']}>
              <div className={styles['header__item']}>
                <Link
                  to={'/'}
                  current={pathname}
                  weight={'thin'}
                  color={'white'}
                  text={'&copy;&nbsp;2018&nbsp;AAG'}
                />
              </div>
              <div className={styles['header__item']}>
                <Link
                  to={'/privacy'}
                  current={pathname}
                  weight={'thin'}
                  color={'white'}
                  text={'Политика конфиденциальности'}
                />
              </div>
            </div>

          </div>
          <button className={styles['header__action']} onClick={this.toggleMenu}>
            <span/>
            <span/>
            <span/>
          </button>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Текущий адрес страницы
   */
  pathname: PropTypes.string,
  /**
   * Адрес до главной страницы
   */
  pathnameToMain: PropTypes.string,
  /**
   * Меню, ожидается `ReactElement` или функция возвращающая его
   */
  Menu: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Показывать меню на десктопном разрешении
   */
  showMenuOnDesktop: PropTypes.bool,
  /**
   * Левый блок, ожидается `ReactElement` или функция возвращающая его
   */
  AsideLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Правый блок, ожидается `ReactElement` или функция возвращающая его
   */
  AsideRight: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default Header;
