import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";
import styles from "./Profile.less";

import { IconCheck } from "Icons";
import {
  Block,
  BlockBox,
  BlockField,
  Form,
  FormBox,
  FormField,
  FormAction,
  Input,
  Button,
  FormError
} from "Elements";

import { updateProfile } from "../../store/user/user.actions";

class Profile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: props.user.name,
      email: props.user.email,
      phone: props.user.phone,
      password: "",
      error: ""
    };

    this.form = React.createRef();
    this.name = React.createRef();
    this.email = React.createRef();
    this.password = React.createRef();

    this.onChangeFieldHandler = this.onChangeFieldHandler.bind(this);
    this.onSubmitProfileForm = this.onSubmitProfileForm.bind(this);
  }

  onChangeFieldHandler(e, value) {
    if (e.target.name === "password") {
      value = value.trim();
    }
    this.setState({
      [e.target.name]: value
    });
  }

  onSubmitProfileForm() {
    this.form.current
      .validate()
      .then(() => {
        return this.props
          .updateProfile(this.props.user.token, {
            name: this.state.name ? this.state.name : null,
            email: this.state.email ? this.state.email : null,
            password: this.state.password ? this.state.password : null
          })
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          });
      })
      .then(() => {
        this.setState({
          password: ""
        });
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err.message);
      });
  }
  render() {
    const { name, email, phone, password, error } = this.state;
    const { className, style, user } = this.props;
    const classes = classNames(styles["profile"], className);

    return (
      <div className={classes} style={style}>
        <Block>
          <Form
            onSubmit={this.onSubmitProfileForm}
            fields={[this.name, this.email, this.password]}
            ref={this.form}
          >
            <FormBox>
              <FormField size={"l"} count={"triple"}>
                <Input
                  label={"Дольщик"}
                  type={"name"}
                  value={name}
                  name={"name"}
                  allowEmpty={true}
                  id={"profile-name"}
                  size={"l"}
                  onChange={this.onChangeFieldHandler}
                  ref={this.name}
                  disabled={true}
                />
              </FormField>
              <FormField size={"l"} count={"triple"}>
                <Input
                  label={"Телефон"}
                  type={"phone"}
                  value={phone}
                  name={"phone"}
                  id={"profile-phone"}
                  onChange={this.onChangeFieldHandler}
                  size={"l"}
                  disabled={true}
                />
              </FormField>
              <FormField size={"l"} count={"triple"}>
                <Input
                  label={"Почта"}
                  type={"email"}
                  value={email}
                  name={"email"}
                  allowEmpty={true}
                  id={"profile-email"}
                  onChange={this.onChangeFieldHandler}
                  size={"l"}
                  ref={this.email}
                  disabled={true}
                />
              </FormField>
              <FormField size={"l"} count={"triple"}>
                <Input
                  label={"Пароль"}
                  type={"password"}
                  value={password}
                  name={"password"}
                  id={"profile-password"}
                  size={"l"}
                  onChange={this.onChangeFieldHandler}
                  ref={this.password}
                />
              </FormField>
              <FormAction size={"l"} count={"triple"} inBlock={true}>
                <Button
                  type={"submit"}
                  color={"red"}
                  size={"l"}
                  text={"Сохранить"}
                  loading={user.isUpdateUserProfileRequest}
                  hiddenTextOnDesktop={true}
                  IconRight={props => <IconCheck {...props} />}
                />
              </FormAction>
            </FormBox>
            {error && <FormError error={error} />}
          </Form>
        </Block>

        {user &&
          user.objects &&
          user.objects.map(object => (
            <Block key={object.id}>
              <BlockBox>
                <BlockField count={"two-thirds"}>
                  <div
                    className={styles["profile__value"]}
                    dangerouslySetInnerHTML={{ __html: object.accountNumber }}
                  />
                  <div
                    className={styles["profile__title"]}
                    dangerouslySetInnerHTML={{ __html: "Номер договора" }}
                  />
                </BlockField>
                <BlockField count={"triple"}>
                  <div
                    className={styles["profile__value"]}
                    dangerouslySetInnerHTML={{ __html: object.number }}
                  />
                  <div
                    className={styles["profile__title"]}
                    dangerouslySetInnerHTML={{ __html: object.type }}
                  />
                </BlockField>
              </BlockBox>
            </Block>
          ))}
      </div>
    );
  }
}

Profile.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (token, config) => {
      return dispatch(updateProfile(token, config));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
