import React             from 'react';
import ReactDOM          from 'react-dom';
import {Provider}        from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import getStore, {history, connect} from './store/client';
import Application                  from './routes';

import './client.less';

const store = getStore();
const {Component, ...props} = Application;

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Component {...props}/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./store/client.js', () => {
    store.replaceReducer(connect)
  });

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Component {...props}/>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
  );
}
