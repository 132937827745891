import client           from '../graph.js';
import dates            from './graphql/dates.graphql';
import times            from './graphql/times.graphql';
import bookRecord       from './graphql/bookRecord.graphql';
import cancelBookRecord from './graphql/cancelBookRecord.graphql';

/**
 * Получить список возможных дат бронирования
 * @return {Promise} -
 */
export function fetchAvailableDates(token) {
  return client.query({
    query: dates,
    fetchPolicy: 'no-cache',
    variables: {
      token,
    }
  })
    .then(response => {
      const result = response['data']['Booking']['dates'];

      return {
        from: result.dateFrom,
        to: result.dateTo,
        free: result.free
      }
    })
}

/**
 * Получить список возможных времени бронирования
 * @return {Promise} -
 */
export function fetchAvailableTime(date) {
  return client.query({
    query: times,
    fetchPolicy: 'no-cache',
    variables: {
      date
    }
  })
    .then(response => {
      const result = response['data']['Booking']['times'];

      return {
        all: result.all,
        free: result.free
      }
    })
}

/**
 * Забронировать просмотр объекта(ов)
 * @param {string} dateTime - дата и время бронирования
 * @param {string} token - токен пользователя
 * @return {Promise} -
 */
export function fetchToBookObjects(dateTime, token) {
  return client.mutate({
    mutation: bookRecord,
    fetchPolicy: 'no-cache',
    variables: {
      dateTime,
      token
    }
  })
    .then(response => {
      const result = response['data']['Booking']['bookRecord'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      return result;
    })
}

/**
 * Снять бронь просмотр объекта(ов)
 * @param {string} bookRecordId - ключ к записи
 * @param {string} token - токен пользователя
 * @return {Promise} -
 */
export function fetchCancelToBookObjects(bookRecordId, token) {
  return client.mutate({
    mutation: cancelBookRecord,
    fetchPolicy: 'no-cache',
    variables: {
      bookRecordId,
      token
    }
  })
    .then(response => {
      const result = response['data']['Booking']['cancelBookRecord'];
      if (result['status'] === 'error') {
        const err = new Error(result['errorDesc']);
        err.code = result['errorCode'];
        throw err;
      }

      return result;
    })
}

