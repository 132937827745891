exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon-lock--1eOgz {\n  width: 12px;\n  height: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"icon-lock": "icon-lock--1eOgz"
};