import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Button.less';

import {IconLoading}        from 'Icons';
import {Link as RouterLink} from 'react-router-dom';

class Button extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * Наведен курсор мыши на элемент
       */
      isStateMouseEnter: false,
      /**
       * Установлен фокус на элемент
       */
      isStateFocus: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  /**
   * Обработчик события наведения курсора на элемент
   * @param {object} event - событие
   */
  onMouseEnterHandler(event) {
    this.setState({
      isStateMouseEnter: true
    });

    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(event);
    }
  }

  /**
   * Обработчик события ухода курсора с элемента
   * @param {object} event - событие
   */
  onMouseLeaveHandler(event) {
    this.setState({
      isStateMouseEnter: false
    });

    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event);
    }
  }

  /**
   * Обработчик клика по элементу
   * @param {object} event - событие
   */
  onClickHandler(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  /**
   * Обработчик события установки фокуса на элемент
   * @param {object} event - событие
   */
  onFocusHandler(event) {
    this.setState({
      isStateFocus: true
    });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  /**
   * Обработчик события снятия фокуса с элемента
   * @param {object} event - событие
   */
  onBlurHandler(event) {
    this.setState({
      isStateFocus: false
    });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }


  render() {
    const {isStateMouseEnter, isStateFocus} = this.state;
    const {className, style, disabled, type, size, padding, children, color, to, href, text, title, target, loading, IconLeft, IconRight, hiddenIconsOnDesktop, hiddenIconsOnMobile, hiddenTextOnDesktop, hiddenTextOnMobile} = this.props;
    const classes = classNames(
      styles['button'],
      size === 's' ? styles['button_size_s'] : null,
      size === 'm' ? styles['button_size_m'] : null,
      size === 'l' ? styles['button_size_l'] : null,
      padding === 'small' ? styles['button_padding_small'] : null,
      padding === 'normal' ? styles['button_padding_normal'] : null,
      padding === 'big' ? styles['button_padding_big'] : null,
      color === 'blue' ? styles['button_color_blue'] : null,
      color === 'red' ? styles['button_color_red'] : null,
      color === 'gray' ? styles['button_color_gray'] : null,
      disabled ? styles['button_state_disabled'] : null,
      loading ? styles['button_state_loading'] : null,
      isStateMouseEnter ? styles['button_state_hover'] : null,
      isStateFocus ? styles['button_state_focus'] : null,
      hiddenIconsOnDesktop ? styles['button_hidden-icons_desktop'] : null,
      hiddenIconsOnMobile ? styles['button_hidden-icons_mobile'] : null,
      hiddenTextOnDesktop ? styles['button_hidden-text_desktop'] : null,
      hiddenTextOnMobile ? styles['button_hidden-text_mobile'] : null,
      className
    );

    if (to) {
      return (
        <RouterLink
          className={classes}
          style={style}
          to={to}
          title={title}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        >
          <span className={styles['button__box']}>
            {IconLeft && (
              <span className={classNames(styles['button__aside'], styles['button__aside_left'])}>
                <IconLeft className={styles['button__icon']}/>
              </span>
            )}
            {text && (
              <span className={styles['button__text']} dangerouslySetInnerHTML={{__html: text}}/>
            )}
            {children}
            {IconRight && (
              <span className={classNames(styles['button__aside'], styles['button__aside_right'])}>
                <IconRight className={styles['button__icon']}/>
              </span>
            )}
          </span>
        </RouterLink>
      )
    }

    if (href) {
      return (
        <a
          className={classes}
          style={style}
          href={href}
          title={title}
          target={target}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        >
          <span className={styles['button__box']}>
            {IconLeft && (
              <span className={classNames(styles['button__aside'], styles['button__aside_left'])}>
                <IconLeft className={styles['button__icon']}/>
              </span>
            )}
            {text && (
              <span className={styles['button__text']} dangerouslySetInnerHTML={{__html: text}}/>
            )}
            {children}
            {IconRight && (
              <span className={classNames(styles['button__aside'], styles['button__aside_right'])}>
                <IconRight className={styles['button__icon']}/>
              </span>
            )}
          </span>
        </a>
      )
    }

    return (
      <button
        className={classes}
        style={style}
        type={type}
        disabled={disabled || loading}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onFocus={this.onFocusHandler}
        onBlur={this.onBlurHandler}
        onClick={this.onClickHandler}
      >
        <span className={styles['button__box']}>
            {IconLeft && (
              <span className={classNames(styles['button__aside'], styles['button__aside_left'])}>
                <IconLeft className={styles['button__icon']}/>
              </span>
            )}
          {text && (
            <span className={styles['button__text']} dangerouslySetInnerHTML={{__html: text}}/>
          )}
          {children}
          {IconRight && (
            <span className={classNames(styles['button__aside'], styles['button__aside_right'])}>
                <IconRight className={styles['button__icon']}/>
              </span>
          )}
          <span className={styles['button__loading']}>
            <IconLoading className={styles['button__icon']}/>
          </span>
          </span>
      </button>
    )
  }
}

Button.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Текст кнопки
   */
  text: PropTypes.string,
  /**
   * Всплывающая подсказка
   */
  title: PropTypes.string,
  /**
   * Размер кнопки
   */
  size: PropTypes.oneOf(['s', 'm', 'l']),
  /**
   * Размер отступов у кнопки
   */
  padding: PropTypes.oneOf(['small', 'normal', 'big']),
  /**
   * Цвет кнопки
   */
  color: PropTypes.oneOf(['blue', 'red', 'gray']),
  /**
   * Если указан to, то кнопка будет ссылкой
   */
  to: PropTypes.string,
  /**
   * Если указан href, то кнопка будет ссылкой (для перехода по внешним ссылкам)
   */
  href: PropTypes.string,
  /**
   * Как открывть ссылку
   */
  target: PropTypes.string,
  /**
   * Показывать индикатор загрузки
   */
  loading: PropTypes.bool,
  /**
   * Заблокирована ли кнопка
   */
  disabled: PropTypes.bool,
  /**
   * Тип кнопки
   */
  type: PropTypes.oneOf(['button', 'submit']),
  /**
   * Обработчик события нажатия на кнопку
   */
  onClick: PropTypes.func,
  /**
   * Обработчик события наведения курсора на кнопку
   */
  onMouseEnter: PropTypes.func,
  /**
   * Обработчик события ухода курсора с кнопки
   */
  onMouseLeave: PropTypes.func,
  /**
   * Обработчик события фокуса кнопки
   */
  onFocus: PropTypes.func,
  /**
   * Обработчик события снятия фокуса кнопки
   */
  onBlur: PropTypes.func,
  /**
   * Скрывать текст на десктопном разрешении
   */
  hiddenTextOnDesktop: PropTypes.bool,
  /**
   * Скрывать иконки на десктопном разрешении
   */
  hiddenIconsOnDesktop: PropTypes.bool,
  /**
   * Скрывать текст на мобильном разрешении
   */
  hiddenTextOnMobile: PropTypes.bool,
  /**
   * Скрывать иконки на мобильном разрешении
   */
  hiddenIconsOnMobile: PropTypes.bool,
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconRight: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default Button;
