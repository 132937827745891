import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';
import {connect}              from 'react-redux';

import Helmet           from 'react-helmet';
import {Link}           from 'Elements';
import {IconArrowRight} from 'Icons';

class NotFoundPage extends PureComponent {
  render() {
    const {className, style} = this.props;

    return (
      <div className={className} style={style}>
        <Helmet>
          <title>Страница не найдена</title>
        </Helmet>
        <h1>Страница не&nbsp;найдена</h1>
        <p>
          <Link to={'/'} text={'На&nbsp;главную'} color={'blue'} weight={'bold'} IconRight={props => <IconArrowRight {...props}/>}/>
        </p>
      </div>
    )
  }
}

NotFoundPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(NotFoundPage));
