import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import styles from "./Link.less";

import { Link as RouterLink } from "react-router-dom";

class Button extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * Наведен курсор мыши на элемент
       */
      isStateMouseEnter: false,
      /**
       * Установлен фокус на элемент
       */
      isStateFocus: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  /**
   * Обработчик события наведения курсора на элемент
   * @param {object} event - событие
   */
  onMouseEnterHandler(event) {
    this.setState({
      isStateMouseEnter: true
    });

    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(event);
    }
  }

  /**
   * Обработчик события ухода курсора с элемента
   * @param {object} event - событие
   */
  onMouseLeaveHandler(event) {
    this.setState({
      isStateMouseEnter: false
    });

    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event);
    }
  }

  /**
   * Обработчик клика по элементу
   * @param {object} event - событие
   */
  onClickHandler(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  /**
   * Обработчик события установки фокуса на элемент
   * @param {object} event - событие
   */
  onFocusHandler(event) {
    this.setState({
      isStateFocus: true
    });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  /**
   * Обработчик события снятия фокуса с элемента
   * @param {object} event - событие
   */
  onBlurHandler(event) {
    this.setState({
      isStateFocus: false
    });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  render() {
    const { isStateMouseEnter, isStateFocus } = this.state;
    const {
      className,
      style,
      children,
      color,
      underline,
      weight,
      to,
      href,
      text,
      title,
      target,
      IconLeft,
      IconRight,
      download,
      disabled
    } = this.props;
    const classes = classNames(
      styles["link"],
      color === "blue" ? styles["link_color_blue"] : null,
      color === "red" ? styles["link_color_red"] : null,
      color === "gray" ? styles["link_color_gray"] : null,
      color === "white" ? styles["link_color_white"] : null,
      color === "current" ? styles["link_color_current"] : null,
      weight === "thin" ? styles["link_weight_thin"] : null,
      weight === "bold" ? styles["link_weight_bold"] : null,
      weight === "extrabold" ? styles["link_weight_extrabold"] : null,
      underline ? styles["link_underline"] : null,
      disabled ? styles["link_disabled"] : null,
      isStateMouseEnter ? styles["link_state_hover"] : null,
      isStateFocus ? styles["link_state_focus"] : null,
      className
    );

    if (to) {
      return (
        <RouterLink
          className={classes}
          style={style}
          to={to}
          title={title}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        >
          {IconLeft && (
            <span
              className={classNames(
                styles["link__aside"],
                styles["link__aside_left"]
              )}
            >
              <IconLeft className={styles["link__icon"]} />
            </span>
          )}
          {text && (
            <span
              className={styles["link__text"]}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {children}
          {IconRight && (
            <span
              className={classNames(
                styles["link__aside"],
                styles["link__aside_right"]
              )}
            >
              <IconRight className={styles["link__icon"]} />
            </span>
          )}
        </RouterLink>
      );
    }

    if (href) {
      return (
        <a
          className={classes}
          style={style}
          href={href}
          title={title}
          target={target}
          download={download}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        >
          {IconLeft && (
            <span
              className={classNames(
                styles["link__aside"],
                styles["link__aside_right"]
              )}
            >
              <IconLeft className={styles["link__icon"]} />
            </span>
          )}
          {text && (
            <span
              className={styles["link__text"]}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {children}
          {IconRight && (
            <span
              className={classNames(
                styles["link__aside"],
                styles["link__aside_right"]
              )}
            >
              <IconRight className={styles["link__icon"]} />
            </span>
          )}
        </a>
      );
    }

    return (
      <button
        className={classes}
        style={style}
        type={"button"}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onFocus={this.onFocusHandler}
        onBlur={this.onBlurHandler}
        onClick={this.onClickHandler}
      >
        {IconLeft && (
          <span
            className={classNames(
              styles["link__aside"],
              styles["link__aside_right"]
            )}
          >
            <IconLeft className={styles["link__icon"]} />
          </span>
        )}
        {text && (
          <span
            className={styles["link__text"]}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {children}
        {IconRight && (
          <span
            className={classNames(
              styles["link__aside"],
              styles["link__aside_right"]
            )}
          >
            <IconRight className={styles["link__icon"]} />
          </span>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Текст кнопки
   */
  text: PropTypes.string,
  /**
   * Всплывающая подсказка
   */
  title: PropTypes.string,
  /**
   * Цвет кнопки
   */
  color: PropTypes.oneOf(["blue", "red", "gray", "white", "current"]),
  /**
   * Вес кнопки
   */
  weight: PropTypes.oneOf(["thin", "bold"]),
  /**
   * Подчеркивать ссылку
   */
  underline: PropTypes.bool,
  /**
   * Если указан to, то кнопка будет ссылкой
   */
  to: PropTypes.string,
  /**
   * Если указан href, то кнопка будет ссылкой (для перехода по внешним ссылкам)
   */
  href: PropTypes.string,
  /**
   * Как открывть ссылку
   */
  target: PropTypes.string,
  /**
   * скачивание без перехода
   */
  download: PropTypes.bool,
  /**
   * Обработчик события нажатия на кнопку
   */
  onClick: PropTypes.func,
  /**
   * Обработчик события наведения курсора на кнопку
   */
  onMouseEnter: PropTypes.func,
  /**
   * Обработчик события ухода курсора с кнопки
   */
  onMouseLeave: PropTypes.func,
  /**
   * Обработчик события фокуса кнопки
   */
  onFocus: PropTypes.func,
  /**
   * Обработчик события снятия фокуса кнопки
   */
  onBlur: PropTypes.func,
  /**
   * Скрывать текст на десктопном разрешении
   */
  hiddenTextOnDesktop: PropTypes.bool,
  /**
   * Скрывать иконки на десктопном разрешении
   */
  hiddenIconsOnDesktop: PropTypes.bool,
  /**
   * Скрывать текст на мобильном разрешении
   */
  hiddenTextOnMobile: PropTypes.bool,
  /**
   * Скрывать иконки на мобильном разрешении
   */
  hiddenIconsOnMobile: PropTypes.bool,
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Иконка справа (ожидается `ReactElement` или функция возвращающая его)
   */
  IconRight: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default Button;
