import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Modal.less';

import {IconClose} from 'Icons';

import ReactDOM from 'react-dom';
import Helmet   from "react-helmet";

const DEFAULT_Z_INDEX = 999;
const ANIMATION_TIME = 500;

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isStateOpen: false,
    };

    this._mounted = false;

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this);

    this.openModal = this.openModal.bind(this, true);
    this.closeModal = this.closeModal.bind(this, true);
  }

  componentDidMount() {
    const modalRoot = document.getElementById('modal');
    this.el = document.createElement('div');

    modalRoot.appendChild(this.el);
    document.addEventListener('keyup', this.onKeyUpHandler);
    document.addEventListener('mousedown', this.onClickHandler);

    this._mounted = true;
  }

  componentWillUnmount() {
    const modalRoot = document.getElementById('modal');

    modalRoot.removeChild(this.el);
    document.removeEventListener('mousedown', this.onClickHandler);
    this._mounted = false;
  }

  setWrapperRef(node) {
    this._wrapper = node;
  }

  onClickHandler(event) {
    if (event.target === this._wrapper) {
      this.closeModal(true)
    }
  }

  onKeyUpHandler(event) {
    if (this.state.showContent && event.keyCode === 27) {
      this.closeModal(true);
    }
  }

  openModal(fireEvent) {
    this.setState({
      isStateOpen: true,
      showContent: true
    });

    if (fireEvent && this.props.onOpen) {
      this.props.onOpen()
    }
  }

  closeModal(fireEvent) {
    if (!this._mounted) return;
    this.setState({
      isStateOpen: false,
      showContent: false,
    });

    if (fireEvent && this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {className, style, size, zIndex, onBackSide, label, children} = this.props;
    const {isStateOpen, showContent} = this.state;
    const classes = classNames(
      styles['modal'],
      isStateOpen ? styles['modal_state_open'] : null,
      size === 'small' ? styles['modal_size_small'] : null,
      size === 'normal' || !size ? styles['modal_size_normal'] : null,
      size === 'big' ? styles['modal_size_big'] : null,
      onBackSide ? styles['modal_state_on-back-side'] : null,
      className
    );

    return this.el ? ReactDOM.createPortal(
      <div className={classes} style={{zIndex: zIndex || DEFAULT_Z_INDEX}}>
        <Helmet>
          {showContent && <body className={styles['body_state_show-modal']}/>}
        </Helmet>
        <div className={styles['modal__wrapper']} ref={this.setWrapperRef}>
          <div className={styles['modal__inner']} style={style}>
            <div className={styles['modal__head']}>
              <div className={styles['modal__label']} dangerouslySetInnerHTML={{__html: label}}/>
              <button className={styles['modal__close']} onClick={this.closeModal}>
                <IconClose/>
              </button>
            </div>
            <div className={styles['modal__body']}>
              {showContent && children}
            </div>
          </div>
        </div>
      </div>,
      this.el
    ) : (
      <div/>
    )
  }
}

Modal.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Заголовок модального окна
   */
  label: PropTypes.string.isRequired,
  /**
   * Элементы, которые отрисуются внутри контентной части модального окна,
   * ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Размер модального окна
   */
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  /**
   * Обработчик события открытия окна
   */
  onOpen: PropTypes.func,
  /**
   * Обработчик события закрытия окна
   */
  onClose: PropTypes.func,
  /**
   * Уровень всплывающего окна
   */
  zIndex: PropTypes.number
};

export default Modal;
