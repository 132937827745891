import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {connect}              from 'react-redux';

import classNames from 'classnames';
import styles     from './SignIn.less';

import { IconArrowRight } from 'Icons';
import { Form, FormBox, FormField, FormAction, FormError, FormMore, Link, Input, Button, Modal } from 'Elements';
import { ResetPassword, Authentication } from 'Components';

import {signIn} from '../../store/user/user.actions'
import {push} from 'react-router-redux';

class SignIn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
      password: '',
      error: ''
    };

    this.form = React.createRef();
    this.phone = React.createRef();
    this.password = React.createRef();
    this.modalReset = React.createRef();

    this.onChangeFieldHandler = this.onChangeFieldHandler.bind(this);
    this.onClickResetHandler = this.onClickResetHandler.bind(this);
    this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
  }

  onChangeFieldHandler(e, value) {
    this.setState({
      [e.target.name]: value
    })
  }

  onClickResetHandler() {
    const { onClickReset } = this.props;
    onClickReset && onClickReset();
    this.modalReset.current.openModal();
  }

  onSubmitLoginForm() {
    this.form.current.validate()
      .then(() => {
        this.setState({
          error: ''
        });
      })
      .then(() => {
        return this.props.signIn(this.state.phone, this.state.password)
          .then(() => {
            if (this.props.onSignIn) {
              this.props.onSignIn();
            } else {
              this.props.goToWelcome();
            }
          })
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          })
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err)
      })
  }

  render() {
    const {error, phone, password} = this.state;
    const {className, style} = this.props;
    const classes = classNames(styles['sign-in'], className);

    return (
      <div className={classes} style={style}>
        <Form onSubmit={this.onSubmitLoginForm} fields={[this.phone, this.password]} ref={this.form}>
          <FormBox>
            <FormField size={'l'} count={'double'} withAction={true}>
              <Input
                label={'Телефон'}
                type={'phone'}
                value={phone}
                name={'phone'}
                id={'sgin-in-phone'}
                onChange={this.onChangeFieldHandler}
                size={'l'}
                ref={this.phone}
              />
            </FormField>
            <FormField size={'l'} count={'double'} withAction={true}>
              <Input
                label={'Пароль'}
                type={'password'}
                value={password}
                name={'password'}
                id={'sgin-in-password'}
                size={'l'}
                onChange={this.onChangeFieldHandler}
                ref={this.password}
              />
            </FormField>
            <FormAction size={'l'}>
              <Button
                type={'submit'}
                color={'red'}
                size={'l'}
                loading={this.props.user.isSignInRequest}
                text={'Войти'}
                hiddenTextOnDesktop={true}
                IconRight={props => <IconArrowRight {...props}/>}
              />
            </FormAction>
          </FormBox>
          {error && (
            <FormError error={error}/>
          )}
        </Form>
        <FormMore>
          <Link weight={'thin'} color={'red'} text={'Восстановить пароль'} onClick={this.onClickResetHandler}/>
        </FormMore>
        <Modal zIndex={1100} label={'Восстановить пароль'} ref={this.modalReset}>
          <ResetPassword onResetPassword={() => {
            this.props.goToWelcome();
          }}/>
        </Modal>
      </div>
    )
  }
}

SignIn.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Связанный генератор действия авторизации
   */
  signIn: PropTypes.func,
  /**
   * Обработчик события нажатия на кнопку сбросить пароль
   */
  onClickReset: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (phone, password) => {
      return dispatch(signIn(phone, password))
    },
    goToWelcome: () => {
      return dispatch(push('/welcome'))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
