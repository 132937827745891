import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";

import classNames from "classnames";
import styles from "./RegulationPage.less";

import Helmet from "react-helmet";
import { Block, Link } from "Elements";

import { PHONE } from "../../utils/constants";

class RegulationPage extends PureComponent {
  render() {
    const { className, style } = this.props;
    const classes = classNames(styles["regulation-page"], className);

    return (
      <div className={classes} style={style}>
        <Helmet>
          <title>Регламент передачи квартиры</title>
        </Helmet>

        <h1>Регламент передачи квартиры</h1>
        <Block>
          <ol>
            <li>
              <h4>Запись на&nbsp;приём квартиры</h4>
              <p>
                Квартиры передают сотрудники отдела сервиса управляющей компании
                A-Service (ООО &laquo;Инновационные стандарты качества&raquo;).
                Чтобы записаться на&nbsp;просмотр, оставьте заявку, позвонив
                по&nbsp;номеру{" "}
                <Link
                  href={`tel:${PHONE}`}
                  color={"white"}
                  underline={true}
                  title="Позвонить нам"
                  text={PHONE}
                  style={{ display: "inline-block" }}
                />
                .
              </p>
            </li>
            <li>
              <h4>Подготовка к&nbsp;передаче</h4>
              <span>
                В&nbsp;назначенную дату и&nbsp;время вы&nbsp;встречаетесь
                на&nbsp;объекте. Не&nbsp;забудьте необходимые документы:
              </span>
              <ul>
                <li>гражданский паспорт,</li>
                <li>
                  оригинал ДДУ со&nbsp;всеми дополнительными соглашениями, если
                  они были заключены.
                </li>
              </ul>
              <br />
              <p>
                Если квартиру принимает ваш представитель, подготовьте
                нотариально заверенную доверенность, наделяющую его
                соответствующими полномочиями. Также сотруднику отдела заселения
                потребуется нотариально заверенная копия доверенности, которую
                он&nbsp;оставит себе.
              </p>
            </li>
            <li>
              <h4>Во&nbsp;время передачи</h4>
              <p>
                Совместно с&nbsp;сотрудником отдела заселения
                вы&nbsp;осматриваете квартиру. По&nbsp;результатам осмотра
                составляется акт, который скрепляется подписью каждой стороны.
                В&nbsp;акте фиксируются замечания и&nbsp;сроки
                их&nbsp;устранения. В&nbsp;этом случае вы&nbsp;ожидаете
                приглашения на&nbsp;повторный осмотр. Если претензии
                отсутствуют, вы&nbsp;подписываете акт без замечаний.
              </p>
            </li>
            <li>
              <h4>Подписание акта приёма-передачи</h4>
              <p>
                После подписания акта приема-передачи сотрудники выдадут вам
                памятку с&nbsp;информацией о&nbsp;дальнейших действиях,
                необходимых для&nbsp;регистрации права собственности
                на&nbsp;квартиру, предложат ознакомиться и&nbsp;подписать
                договор с&nbsp;управляющей компанией и&nbsp;выдадут ключи.
              </p>
              <p>
                При необходимости сотрудники отдела заселения ответят
                на&nbsp;ваши вопросы и&nbsp;скоординируют дальнейшие действия.
              </p>
            </li>
          </ol>
        </Block>
      </div>
    );
  }
}

RegulationPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegulationPage)
);
