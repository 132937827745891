import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Calendar.less';

import {IconArrow2Left, IconArrow2Right} from 'Icons';

import moment from 'moment';

moment.locale('ru');

class Calendar extends PureComponent {
  constructor(props) {
    super(props);

    const from = props.from && moment.utc(props.from).isValid()
      ? moment.utc(props.from).startOf('day')
      : moment.utc().startOf('day');

    const to = props.to && moment.utc(props.to).isValid()
      ? moment.utc(props.to).startOf('day')
      : moment.utc().add(11, 'months');

    this.state = {
      from: from,
      to: to,
      free: props.free ? props.free : null,
      current: from.clone().startOf('month'),
      selected: null,
      moveBackDisabled: true,
      moveNextDisabled: false,
    };

    this.onClickMoveBackHandler = this.onClickMoveBackHandler.bind(this);
    this.onClickMoveNextHandler = this.onClickMoveNextHandler.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const from = props.from && moment.utc(props.from).isValid()
      ? moment.utc(props.from).startOf('day')
      : moment.utc().startOf('day');

    const to = props.to && moment.utc(props.to).isValid()
      ? moment.utc(props.to).startOf('day')
      : moment.utc().add(11, 'months');

    if (!from.isSame(state.from) || !to.isSame(state.to)) {
      return {
        from: from,
        to: to,
        free: props.free ? props.free : null,
        current: from.clone().startOf('month'),
        moveBackDisabled: true,
        moveNextDisabled: false,
      };

      return null;
    }

    return {}
  }

  onClickMoveBackHandler() {
    this.setState((state) => {
      const current = state.current.clone().subtract(1, 'month');

      return {
        current: current,
        moveNextDisabled: false,
        moveBackDisabled: state.from.clone().startOf('month').isSame(current)
      }
    });
  }

  onClickMoveNextHandler() {
    this.setState((state) => {
      const current = state.current.clone().add(1, 'month');

      return {
        current: current,
        moveNextDisabled: state.to.clone().startOf('month').isSame(current),
        moveBackDisabled: false
      };
    });
  }

  onClickDateHandler(date) {
    this.setState({
      selected: date
    });

    if (this.props.onChange) {
      this.props.onChange(date.format('DD.MM.YYYY'))
    }
  }

  render() {
    const {className, style} = this.props;
    const {current, from, to, selected, free, moveBackDisabled, moveNextDisabled} = this.state;
    const classes = classNames(styles['calendar'], className);

    const head = current.format('MMMM YYYY');
    const daysInMonth = current.daysInMonth();
    const startDayOfWeek = current.day() - 1;

    return (
      <div className={classes} style={style}>
        <div className={styles['calendar__head']}>
          <div className={styles['calendar__date']} dangerouslySetInnerHTML={{__html: head}}/>
          <div className={styles['calendar__actions']}>
            {moveBackDisabled
              ? (
                <div className={classNames(styles['calendar__action'], styles['calendar__action_disabled'])}>
                  <IconArrow2Left/>
                </div>
              ) : (
                <button type={'button'} className={styles['calendar__action']} onClick={this.onClickMoveBackHandler}>
                  <IconArrow2Left/>
                </button>
              )
            }
            {moveNextDisabled
              ? (
                <div className={classNames(styles['calendar__action'], styles['calendar__action_disabled'])}>
                  <IconArrow2Right/>
                </div>
              ) : (
                <button type={'button'} className={styles['calendar__action']} onClick={this.onClickMoveNextHandler}>
                  <IconArrow2Right/>
                </button>
              )
            }
          </div>
        </div>
        <div className={styles['calendar__body']}>
          <div className={classNames(styles['calendar__items'], styles['calendar__items_labels'])}>
            <div className={styles['calendar__label']} dangerouslySetInnerHTML={{__html: 'Пн'}}/>
            <div className={styles['calendar__label']} dangerouslySetInnerHTML={{__html: 'Вт'}}/>
            <div className={styles['calendar__label']} dangerouslySetInnerHTML={{__html: 'Ср'}}/>
            <div className={styles['calendar__label']} dangerouslySetInnerHTML={{__html: 'Чт'}}/>
            <div className={styles['calendar__label']} dangerouslySetInnerHTML={{__html: 'Пт'}}/>
            <div
              className={classNames(styles['calendar__label'], styles['calendar__label_weekend'])}
              dangerouslySetInnerHTML={{__html: 'Сб'}}
            />
            <div
              className={classNames(styles['calendar__label'], styles['calendar__label_weekend'])}
              dangerouslySetInnerHTML={{__html: 'Вс'}}
            />
          </div>
          <div className={styles['calendar__items']}>
            {startDayOfWeek > 0 && [...Array(startDayOfWeek)].map((item, index) => (
              <div key={index} className={styles['calendar__item']}/>
            ))}

            {[...Array(daysInMonth)].map((item, index) => {
              const currentDate = current.clone().add(index, 'day');
              let isFree = false;

              free && free.map((freeDate) => {
                if (moment(freeDate).isSame(currentDate)) {
                  isFree = true;
                }
              });

              if (currentDate.isBefore(from) || currentDate.isAfter(to) || !isFree) {
                return (
                  <div key={index} className={classNames(styles['calendar__item'], styles['calendar__item_disabled'])}>
                    <span dangerouslySetInnerHTML={{__html: (index + 1)}}/>
                  </div>
                )
              }

              const classes = classNames(
                styles['calendar__item'],
                currentDate.isSame(selected) ? styles['calendar__item_selected'] : null,
              );

              return (
                <button
                  type={'button'}
                  key={index}
                  className={classes}
                  onClick={this.onClickDateHandler.bind(this, currentDate)}
                >
                  <span dangerouslySetInnerHTML={{__html: (index + 1)}}/>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Массив отображаемых времён
   */
  from: PropTypes.string,
  /**
   * Массив отображаемых времён
   */
  to: PropTypes.string,
  /**
   * Массив заблокированных времён
   */
  free: PropTypes.array.isRequired
};

export default Calendar;
