import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';
import {connect}              from 'react-redux';

import classNames from 'classnames';
import styles     from './PrivacyPage.less';

import Helmet from 'react-helmet'

class PrivacyPage extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['privacy-page'], className);

    return (
      <div className={classes} style={style}>
        <Helmet>
          <title>Политика конфиденциальности</title>
        </Helmet>

        <h1>Политика конфиденциальности</h1>
        <p>
          Заполнением и&nbsp;отправкой настоящей Заявки, Я, в&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;27&nbsp;июля 2006
          года &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo;, выражаю свое добровольное согласие на&nbsp;обработку, включая использование
          и&nbsp;передачу, моих персональных данных, содержащихся в&nbsp;настоящей Заявке, следующим лицам: Общество с&nbsp;ограниченной
          ответственностью &laquo;Инновационные стандарты качества&raquo; (190005&nbsp;г. Санкт- Петербург, ул. Парфёновская, д.&nbsp;4, стр.&nbsp;1, пом. 8-Н;
          ОГРН 1187847160192), далее по&nbsp;тексту&nbsp;&mdash; Компания, в&nbsp;целях организации деятельности по&nbsp;управлению недвижимым имуществом
          за&nbsp;вознаграждение или на&nbsp;договорной основе и/или сопутствующей деятельности на&nbsp;условиях ниже:
        </p>
        <ol>
          <li>
            Любые сведения, содержащиеся в&nbsp;Заявке, могут быть в&nbsp;любое время проверены или перепроверены Компанией непосредственно или с&nbsp;помощью
            уполномоченных представителей с использованием любых доступных источников информации; все сведения, содержащиеся в&nbsp;настоящей Заявке и&nbsp;все
            данные, затребованные и&nbsp;полученные Компанией во&nbsp;исполнение своих договорных обязательств перед Компанией, предназначаются для передачи
            их&nbsp;Компании с&nbsp;целью организации надлежащего исполнения обязательств перед Заявителем. При этом Компания оставляет за&nbsp;собой право
            использовать указанные сведения (данные) как доказательство при судебном разбирательстве или в&nbsp;иных случаях проведения официального
            разбирательства, для целей проведения маркетинговых или иных подобных исследований, направленных на&nbsp;улучшение качества обслуживания клиентов
            (контрагентов) Компании, для направления рекламы по&nbsp;сетям электросвязи или обзвона с&nbsp;целью рекламы (предложения) продуктов Компании,
            а&nbsp;равно ведения Компанией клиентской базы;
          </li>
          <li>
            Обработка персональных данных будет осуществляться путем ведения электронной базы данных, а в&nbsp;отношении персональных данных будут совершаться
            такие действия, как сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в&nbsp;том числе
            передача), обезличивание, блокирование, уничтожение;
          </li>
          <li>
            Согласие на&nbsp;обработку персональных данных, содержащееся в&nbsp;настоящей Заявке, действует в течение&nbsp;75 (семидесяти пяти) лет с&nbsp;даты
            отправки настоящей Заявки;
          </li>
          <li>
            Подтверждаю, что сведения, содержащиеся в&nbsp;настоящей Заявке, являются верными и&nbsp;точными;
          </li>
          <li>
            Подтверждаю, что ознакомлен и&nbsp;согласен с&nbsp;Политикой Компаний в&nbsp;отношении обработки персональных данных;
          </li>
          <li>
            Даю согласие на&nbsp;передачу информации в&nbsp;электронной форме по&nbsp;сети Интернет.
          </li>
        </ol>
      </div>
    )
  }
}

PrivacyPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(PrivacyPage));
