exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".icon-arrow-right--2NLfI {\n  width: 14px;\n  height: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"icon-arrow-right": "icon-arrow-right--2NLfI"
};