import {constants} from './user.actions.js'

let token = '';

if (typeof (window) !== 'undefined') {
  token = localStorage.getItem('token');
}

/**
 * Редьюсер хранилища бронирований
 * @param {object} state - текущее состояние
 * @param {object} action - текущее действие
 * @return {object} - новое состояние хранилища
 */
export default function (state = {time: '', token}, action) {
  switch (action.type) {

    // пошел запрос на регистрацию
    case constants.SIGN_UP_REQUEST:
      return {
        ...state,
        isSignUpRequest: true
      };

    // запрос на регистрацию завершился ошибкой
    case constants.SIGN_UP_FAILURE:
      return {
        ...state,
        isSignUpRequest: false
      };

    // пошел запрос на авторизацию
    case constants.SIGN_IN_REQUEST:
      return {
        ...state,
        isSignInRequest: true
      };

    // запрос на авторизацию завершился ошибкой
    case constants.SIGN_IN_FAILURE:
      return {
        ...state,
        isSignInRequest: false
      };

    // пошел запрос на авторизацию по коду
    case constants.SIGN_IN_BY_CODE_REQUEST:
      return {
        ...state,
        isSignInByCodeRequest: true
      };

    // запрос на авторизацию по коду завершился ошибкой
    case constants.SIGN_IN_BY_CODE_FAILURE:
      return {
        ...state,
        isSignInByCodeRequest: false
      };

    // пошел запрос на получение данных о пользователе
    case constants.GET_USER_DATA_REQUEST:
      return {
        ...state,
        isGetUserDataRequest: true
      };

    // запрос данных о пользователе завершился ошибкой
    case constants.GET_USER_DATA_FAILURE:
      return {
        ...state,
        isGetUserDataRequest: false
      };

    // при удачной регистрации
    case constants.SIGN_UP_SUCCESS:
    // при удачной авторизации
    case constants.SIGN_IN_SUCCESS:
    // при удачном восстановлении пароля
    case constants.SIGN_IN_BY_CODE_SUCCESS:
    // при удачном запросе данных пользователя
    case constants.GET_USER_DATA_SUCCESS:

      return {
        ...state,
        token: action.token,
        id: action.id,
        name: action.name,
        phone: action.phone,
        email: action.email,
        objects: action.objects,
        booking: action.booking,
        isSignUpRequest: false,
        isSignInRequest: false,
        isSignInByCodeRequest: false,
        isGetUserDataRequest: false
      };

    // пошел запрос на отправку смс
    case constants.SEND_PHONE_REQUEST:
      return {
        ...state,
        isSendingCode: true
      };

    // отправка смс завершилась успешно
    case constants.SEND_PHONE_SUCCESS:
      return {
        ...state,
        isSendingCode: false,
        time: action.time,
      };

    // отправка смс завершилась ошибкой
    case constants.SEND_PHONE_FAILURE:
      return {
        ...state,
        isSendingCode: false,
        time: action.time
      };

    // выход
    case constants.SIGN_OUT:
      return {
        token: ''
      };

    case constants.SUBSCRIBE_REQUEST:
      return {
        ...state,
        isSubscribeRequest: true
      };

    case constants.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubscribeRequest: false,
        subscribe: true
      };

    case constants.SUBSCRIBE_FAILURE:
      return {
        ...state,
        isSubscribeRequest: false
      };

    case constants.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        isUpdateUserProfileRequest: true
      };

    case constants.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdateUserProfileRequest: false,
        name: action.name,
        email: action.email
      };

    case constants.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isUpdateUserProfileRequest: false
      };


    default:
      return state;
  }
}
