import React, {PureComponent} from 'react';

import classNames from 'classnames';
import styles     from './Navigation.less';

class Navigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClickNavigationButtonHandler = this.onClickNavigationButtonHandler.bind(this);
  }

  onClickNavigationButtonHandler(index) {
    if (this.props.onClickNavigation) {
      this.props.onClickNavigation(index);
    }
  }

  render() {
    const {className, count, active} = this.props;
    const classes = classNames(styles['navigation'], className);

    return (
      <span className={classes}>
        {[...Array(count)].map((s, index) => {
          const number = (index + 1 < 10 ? '0' : '') + (index + 1);

          if (index + 1 === active) {
            return (
              <button
                key={index}
                className={classNames(styles['navigation__item'], styles['navigation__item_active'])}
                dangerouslySetInnerHTML={{__html: number}}
              />
            )
          }

          return (
            <button
              key={index}
              className={styles['navigation__item']}
              dangerouslySetInnerHTML={{__html: number}}
              onClick={() => {
                this.onClickNavigationButtonHandler(index + 1)
              }}
            />
          )
        })}
      </span>
    )
  }
}

export default Navigation
