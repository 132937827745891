import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './FormBox.less';

class FormBox extends PureComponent {
  render() {
    const {className, style, children} = this.props;
    const classes = classNames(styles['form-box'], className);

    return (
      <div className={classes} style={style}>
        {children}
      </div>
    )
  }
}

FormBox.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
};

export default FormBox;
