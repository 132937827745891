import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';
import {connect}              from 'react-redux';

import classNames from 'classnames';
import styles     from './ProfilePage.less';

import Helmet    from 'react-helmet'
import {Profile} from 'Components';

class ProfilePage extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['profile-page'], className);

    return (
      <div className={classes} style={style}>
        <Helmet>
          <title>Мои объекты</title>
        </Helmet>
        <h1>Мои объекты</h1>
        <Profile/>
      </div>
    )
  }
}

ProfilePage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
