import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './FormField.less';

class FormField extends PureComponent {
  render() {
    const {className, style, children, size, count, withAction} = this.props;
    const classes = classNames(
      styles['form-field'],
      size === 's' ? styles['form-field_size_s'] : null,
      size === 'm' ? styles['form-field_size_m'] : null,
      size === 'l' ? styles['form-field_size_l'] : null,
      withAction ? styles['form-field_size_with-action'] : null,
      count === 'single' ? styles['form-field_count_single'] : null,
      count === 'double' ? styles['form-field_count_double'] : null,
      count === 'triple' ? styles['form-field_count_triple'] : null,
      count === 'quarter' ? styles['form-field_count_quarter'] : null,
      count === 'fill' ? styles['form-field_count_fill'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {children}
      </div>
    )
  }
}

FormField.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Размер блока
   */
  size: PropTypes.oneOf(['s', 'm', 'l']).isRequired,
  /**
   * Кол-во элементов в строку
   */
  count: PropTypes.oneOf(['single', 'double', 'triple', 'quarter', 'fill']).isRequired,
  /**
   * Считать размер с учётом кнопки действия
   */
  withAction: PropTypes.bool
};

export default FormField;
