import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import classNames         from 'classnames';
import {hot}              from "react-hot-loader";
import {connect}          from "react-redux";

import {IconArrowRight}                                                              from 'Icons';
import {Button, Input, Form, FormBox, FormField, FormAction, FormError, Link, Modal} from 'Elements';
import {ConfirmPhone}                                                                from 'Components';

import {sendCode, signInByCode} from "../../store/user/user.actions";
import { PHONE } from '../../utils/constants';


class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        phone: '',
      }
    };

    this.form = React.createRef();
    this.phone = React.createRef();
    this.modalConfirm = React.createRef();

    this.onChangeFieldHandler = this.onChangeFieldHandler.bind(this);
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
  }


  onChangeFieldHandler(event, value) {
    const name = event.target.name;
    this.setState(state => {
      return {
        values: {
          ...state.values,
          [name]: value
        }
      }
    })
  }

  onSubmitFormHandler() {
    return this.form.current.validate()
      .then(() => {
        this.setState({
          error: ''
        })
      })
      .then(() => {
        return this.props.sendCode(this.state.values.phone)
          .then(() => {
            this.modalConfirm.current.openModal();
          })
          .catch(err => {
            this.setState({
              error: err.message
            });
            this.form.current.shake();
          })
      })
      .catch(err => {
        /*global __IS_DEV__ */
        __IS_DEV__ && console.error(err.message)
      })
  }

  render() {
    const {className, style} = this.props;
    const {phone, error} = this.state.values;
    const classes = classNames('reset-password', className);

    return (
      <div className={classes} style={style}>
        <div className={'reset-password__info'}>
          <p>
            Введите телефон, который вы&nbsp;указывали при заключении договора.
          </p>
          <p>
            Если вы не&nbsp;помните номер, свяжитесь с&nbsp;нами
            <br/> по&nbsp;телефону: <Link
            text={PHONE} color={'current'} underline={true} href={`tel:${PHONE}`}/>
            <br/>
            Мы&nbsp;работаем с&nbsp;понедельника по&nbsp;пятницу с&nbsp;10:00 до&nbsp;19:00.
          </p>
        </div>
        <Form ref={this.form} onSubmit={this.onSubmitFormHandler} fields={[this.phone]}>
          <FormBox>
            <FormField size={'l'} count={'single'} withAction={true}>
              <Input
                label={'Телефон'}
                type={'phone'}
                name={'phone'}
                id={'reset-password-phone'}
                size={'l'}
                value={phone}
                onChange={this.onChangeFieldHandler}
                validation={true}
                ref={this.phone}
              />
            </FormField>
            <FormAction size={'l'}>
              <Button
                type={'submit'}
                color={'red'}
                size={'l'}
                loading={this.props.user.isSendingCode}
                hiddenTextOnDesktop={true}
                text={'Отправить код'}
                IconRight={props => <IconArrowRight {...props}/>}
              />
            </FormAction>
          </FormBox>
          {error && (
            <FormError error={error}/>
          )}
        </Form>
        <Modal size={'normal'} label={'Подтвердите телефон'} zIndex={1200} ref={this.modalConfirm}>
          <ConfirmPhone
            delay={this.props.user.time}
            phone={phone}
            onSubmitCode={code => this.props.signInByCode(phone, code)}
            isLoading={this.props.user.isSignUpRequest}
            onConfirmPhone={() => {
              if (this.props.onResetPassword) {
                this.props.onResetPassword();
              }
              if (this.modalConfirm.current) {
                this.modalConfirm.current.closeModal();
              }
            }}
            onResendCode={() => this.props.sendCode(phone)}
            ref={this.confirmPhone}
          />
        </Modal>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.string,
  /**
   * Колбэк при сбросе пароля
   */
  onResetPassword: PropTypes.func,
  /**
   * Хранилище пользователя
   */
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (phone) => {
      return dispatch(sendCode(phone))
    },
    signInByCode: (phone, code) => {
      return dispatch(signInByCode(phone, code))
    }
  }
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
