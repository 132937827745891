import {GET_PAGE_CONTENT_REQUEST, GET_PAGE_CONTENT_SUCCESS, GET_PAGE_CONTENT_FAILURE} from './content.actions.js'

/**
 * Редьюсер хранилища контента страниц
 * @param {object} state - текущее состояние
 * @param {object} action - текущее действие
 * @return {object} - новое состояние хранилища
 */
export default function (state = {}, action) {
  switch (action.type) {
    case GET_PAGE_CONTENT_REQUEST:
      return {
        ...state,
        [action.name]: {
          loading: true
        }
      };

    case GET_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        [action.name]: {
          loading: false
        }
      };

    case GET_PAGE_CONTENT_FAILURE:
      return {
        ...state,
        [action.name]: {
          loading: false,
          code: action.code,
          message: action.message
        }
      };

    default:
      return state;
  }
}
