import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';
import {connect}              from 'react-redux';

import {Intro} from 'Components';

class MainPage extends PureComponent {
  constructor(props) {
    super();
  }

  render() {
    const {className, style} = this.props;

    return (
      <Intro/>
    )
  }
}

MainPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(MainPage));
