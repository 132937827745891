import * as services from './booking.services';

export const constants = {
  GET_AVAILABLE_DATES_REQUEST: 'GET_AVAILABLE_DATES_REQUEST',
  GET_AVAILABLE_DATES_SUCCESS: 'GET_AVAILABLE_DATES_SUCCESS',
  GET_AVAILABLE_DATES_FAILURE: 'GET_AVAILABLE_DATES_FAILURE',

  GET_AVAILABLE_TIMES_REQUEST: 'GET_AVAILABLE_TIMES_REQUEST',
  GET_AVAILABLE_TIMES_SUCCESS: 'GET_AVAILABLE_TIMES_SUCCESS',
  GET_AVAILABLE_TIMES_FAILURE: 'GET_AVAILABLE_TIMES_FAILURE',

  ClEAN_AVAILABLE_TIMES: 'ClEAN_AVAILABLE_TIMES',

  GET_BOOKABLE_OBJECTS_REQUEST: 'GET_BOOKABLE_OBJECTS_REQUEST',
  GET_BOOKABLE_OBJECTS_SUCCESS: 'GET_BOOKABLE_OBJECTS_SUCCESS',
  GET_BOOKABLE_OBJECTS_FAILURE: 'GET_BOOKABLE_OBJECTS_FAILURE',

  GET_RESERVED_OBJECTS_REQUEST: 'GET_RESERVED_OBJECTS_REQUEST',
  GET_RESERVED_OBJECTS_SUCCESS: 'GET_RESERVED_OBJECTS_SUCCESS',
  GET_RESERVED_OBJECTS_FAILURE: 'GET_RESERVED_OBJECTS_FAILURE',

  RESERVE_REQUEST: 'RESERVE_REQUEST',
  RESERVE_SUCCESS: 'RESERVE_SUCCESS',
  RESERVE_FAILURE: 'RESERVE_FAILURE',

  CANCEL_RESERVE_REQUEST: 'CANCEL_RESERVE_REQUEST',
  CANCEL_RESERVE_SUCCESS: 'CANCEL_RESERVE_SUCCESS',
  CANCEL_RESERVE_FAILURE: 'CANCEL_RESERVE_FAILURE'
};

/**
 * Получить возможные даты бронирования
 * @return {function}
 */
export function getAvailableDates(token) {
  return dispatch => {
    dispatch(getAvailableDatesRequest());

    return services.fetchAvailableDates(token)
      .then(({from, to, free}) => dispatch(getAvailableDatesSuccess(from, to, free)))
      .catch(err => dispatch(getAvailableDatesFailure(err)))
  };

  function getAvailableDatesRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_DATES_REQUEST,
          })
        })
    }
  }

  function getAvailableDatesSuccess(from, to, free) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_DATES_SUCCESS,
            from,
            to,
            free
          })
        })
    }
  }

  function getAvailableDatesFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_DATES_FAILURE,
            err
          })
        })
    }
  }
}

/**
 * Получить возможные времена бронирования
 * @props {string} date - дата, для которой ищем время
 * @return {function}
 */
export function getAvailableTimes(date) {
  return dispatch => {
    dispatch(getAvailableTimesRequest());

    return services.fetchAvailableTime(date)
      .then(({all, free}) => dispatch(getAvailableTimesSuccess(all, free)))
      .catch(err => dispatch(getAvailableTimesFailure(err)))
  };

  function getAvailableTimesRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_TIMES_REQUEST
          })
        })
    }
  }

  function getAvailableTimesSuccess(all, free) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_TIMES_SUCCESS,
            all,
            free
          })
        })
    }
  }

  function getAvailableTimesFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_AVAILABLE_TIMES_FAILURE,
            err
          })
        })
    }
  }
}
/**
 * Очистить возможные времена бронирования
 * @props {string} date - дата, для которой ищем время
 * @return {function}
 */
export function cleanAvailableTimes() {
  return dispatch => {
    return Promise.resolve()
      .then(() => {
        return dispatch({
          type: constants.ClEAN_AVAILABLE_TIMES
        })
      })
  }
}

/**
 * Получить список забронированых объектов
 * @param {string} datetime - дата и время бронирования
 * @param {string} token - ключ пользователя
 * @return {function} -
 */
export function toBookObjects(datetime, token) {
  return dispatch => {
    dispatch(toBookObjectsRequest());

    return services.fetchToBookObjects(datetime, token)
      .then(data => dispatch(toBookObjectsSuccess(data)))
      .catch(err => dispatch(toBookObjectsFailure(err)))
  };

  function toBookObjectsRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.RESERVE_REQUEST
          })
        })
    }
  }

  function toBookObjectsSuccess(data) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.RESERVE_SUCCESS,
            data
          })
        })
    }
  }

  function toBookObjectsFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.RESERVE_FAILURE,
            err
          })
        })
        .then(() => {
          throw (err);
        })
    }
  }
}

/**
 * Получить список забронированых объектов
 * @param {string} bookRecordId - ключ бронирования
 * @param {string} token - ключ пользователя
 * @return {function} -
 */
export function cancelToBookObjects(bookRecordId, token) {
  return dispatch => {
    dispatch(cancelToBookObjectsRequest());

    return services.fetchCancelToBookObjects(bookRecordId, token)
      .then(() => dispatch(cancelToBookObjectsSuccess()))
      .catch(err => dispatch(cancelToBookObjectsFailure(err)))
  };

  function cancelToBookObjectsRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.CANCEL_RESERVE_REQUEST
          })
        })
    }
  }

  function cancelToBookObjectsSuccess() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.CANCEL_RESERVE_SUCCESS,
          })
        })
    }
  }

  function cancelToBookObjectsFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.CANCEL_RESERVE_FAILURE,
            err
          })
        })
        .then(() => {
          throw (err);
        })
    }
  }
}
