import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './Icon.less';

class Icon extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-quote'], className);

    return (
      <svg {...this.props} xmlns="http://www.w3.org/2000/svg"/>
    )
  }
}

Icon.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента,
   * ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Атрибут viewBox svg-элемента
   */
  viewBox: PropTypes.string,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};

export default Icon;
