import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";

import classNames from 'classnames';
import styles     from './IconQuote.less';

import {Icon} from '../Icons';

export default class IconQuote extends PureComponent {
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['icon-quote'], className);

    return (
      <Icon className={classes} style={style} viewBox={'0 0 35 27'}>
        <path
          fill="currentColor"
          d="M7.702 0c2.054 0 3.851.79 5.391 2.37s2.311 3.555 2.311 5.925c0 3.476-.553 6.379-1.659 8.709-1.106 2.33-2.33 4.207-3.673 5.628-1.58 1.659-3.357 3.002-5.332 4.029l-2.37-2.962c.869-.553 1.698-1.264 2.488-2.133.632-.711 1.224-1.58 1.777-2.607.553-1.027.908-2.212 1.066-3.555-2.054 0-3.851-.77-5.391-2.311-1.54-1.54-2.311-3.338-2.311-5.391 0-2.054.77-3.851 2.311-5.391 1.54-1.54 3.338-2.311 5.391-2.311zm19.551 0c2.054 0 3.851.79 5.391 2.37s2.311 3.555 2.311 5.925c0 3.476-.553 6.379-1.659 8.709-1.106 2.33-2.33 4.207-3.673 5.628-1.58 1.659-3.357 3.002-5.332 4.029l-2.37-2.962c.869-.553 1.698-1.264 2.488-2.133.632-.711 1.224-1.58 1.777-2.607.553-1.027.908-2.212 1.066-3.555-2.054 0-3.851-.77-5.391-2.311-1.54-1.54-2.311-3.338-2.311-5.391 0-2.054.77-3.851 2.311-5.391 1.54-1.54 3.338-2.311 5.391-2.311z"/>
      </Icon>
    )
  }
}

IconQuote.propTypes = {
  /**
   * Дополнительный класс для иконки
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили для иконки
   */
  style: PropTypes.object,
  /**
   * Цвет иконки
   */
  color: PropTypes.oneOf(['blue', 'red'])
};
