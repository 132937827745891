import React, {Component} from 'react';
import PropTypes          from 'prop-types';
import {connect}          from "react-redux";

import classNames from 'classnames';
import styles     from './Authentication.less';

import {IconArrowRight}              from 'Icons';
import {Link, Modal}                 from 'Elements';
import {SignUp, SignIn, BookingForm, SuccessModal} from "Components";

import {push} from 'react-router-redux';

class Authentication extends Component {
  constructor(props) {
    super(props);

    this.modalSignIn = React.createRef();
    this.modalBooking = React.createRef();
  }
  render() {
    const {className, style} = this.props;
    const classes = classNames(styles['authentication'], className);

    return (
      <div className={classes} style={style}>

        <div className={styles['authentication__head']}>
          <div className={styles['authentication__more']}>
            <Link
              className={styles['authentication__link']}
              text={'Проверить запись'}
              color={'red'}
              weight={'bold'}
              onClick={() => this.props.user.token ? this.props.goToWelcome() : this.modalSignIn.current.openModal()}
              IconRight={(props) => (<IconArrowRight {...props}/>)}
            >
            </Link>
          </div>
        </div>

        {/*<SignUp onSignUp={() => this.modalBooking.current.openModal()}/>*/}

        <Modal size={'big'} label={'Проверить запись на ключи'} ref={this.modalSignIn}>
          <SignIn onSignIn={() => this.props.goToWelcome()}/>
        </Modal>

        <Modal size={'big'} label={'Записаться на&nbsp;получение ключей'} ref={this.modalBooking} onClose={() => {
          this.props.goToService()
        }}>
          <BookingForm onBooking={(message) => {
            window.localStorage.setItem('openSuccessModal', message);
            this.props.goToBooking();
          }}/>
        </Modal>
      </div>
    )
  }
}

Authentication.propTypes = {
  /**
   * Дополнительный класс для стилизации извне
   */
  className: PropTypes.string,
  /**
   * CSS для стилизации извне
   */
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToBooking: () => {
      return dispatch(push('/bookings'))
    },
    goToWelcome: () => {
      return dispatch(push('/welcome'))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
