import moment        from 'moment';
import * as services from './user.services';

export const constants = {
  SEND_PHONE_REQUEST: 'SEND_PHONE_REQUEST',
  SEND_PHONE_SUCCESS: 'SEND_PHONE_SUCCESS',
  SEND_PHONE_FAILURE: 'SEND_PHONE_FAILURE',

  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  GET_USER_DATA_REQUEST: 'GET_USER_DATA_REQUEST',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  SIGN_IN_BY_CODE_REQUEST: 'SIGN_IN_BY_CODE_REQUEST',
  SIGN_IN_BY_CODE_SUCCESS: 'SIGN_IN_BY_CODE_SUCCESS',
  SIGN_IN_BY_CODE_FAILURE: 'SIGN_IN_BY_CODE_FAILURE',

  SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
  SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',

  SIGN_OUT: 'SIGN_OUT'
};

/**
 * Отправить смс на намер пользователя из базы.
 * @param {string} phone - номер телефона
 * @param {boolean} createIsNotExist - создать пользователя, если не существует
 * @return {function} -
 */
export function sendCode(phone, createIsNotExist = true) {
  return dispatch => {
    dispatch(sendCodeRequest());

    return services.fetchSendCode(phone, createIsNotExist)
      .then(({time}) => {
        const momentTime = moment.utc(time);

        if (momentTime.isValid()) {
          return dispatch(sendCodeSuccess(momentTime.toISOString()))
        }

        throw new Error('Время не валидно');
      })
      .catch(err => dispatch(sendCodeFailure(err)))
  };

  function sendCodeRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SEND_PHONE_REQUEST
          })
        })
    }
  }

  function sendCodeSuccess(time) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SEND_PHONE_SUCCESS,
            time
          })
        })
    }
  }

  function sendCodeFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.SEND_PHONE_FAILURE,
            err,
            time: moment.utc().add(360, 'seconds').toISOString()
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}

/**
 * Зарегистрировать пользователя.
 * @param {string} phone - номер телефона
 * @param {string} code - код из смс
 * @return {function}
 */
export function signUp(phone, code) {
  return dispatch => {
    dispatch(signUpRequest());

    return services.fetchSignUp(phone, code)
      .then((config) => dispatch(signUpSuccess(config)))
      .catch(err => dispatch(signUpFailure(err)))
  };

  function signUpRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_UP_REQUEST
          })
        })
    }
  }

  function signUpSuccess({token, user}) {
    localStorage.setItem('token', token);

    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_UP_SUCCESS,
            token,
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            objects: user.objects,
            booking: user.booking
          })
        })
    }
  }

  function signUpFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.SIGN_UP_FAILURE
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}

/**
 * Восстановить пароль и авторизовать пользователя.
 * @param {string} phone - номер телефона
 * @param {string} code - код из смс
 * @return {function}
 */
export function signInByCode(phone, code) {
  return dispatch => {
    dispatch(signInByCodeRequest());

    return services.fetchSignInByCode(phone, code)
      .then(config => dispatch(signInByCodeSuccess(config)))
      .catch(err => dispatch(signInByCodeFailure(err)))
  };

  function signInByCodeRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_UP_REQUEST
          })
        })
    }
  }

  function signInByCodeSuccess({token, user}) {
    localStorage.setItem('token', token);

    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_UP_SUCCESS,
            token,
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            objects: user.objects,
            booking: user.booking
          })
        })
    }
  }

  function signInByCodeFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.SIGN_UP_FAILURE
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}

export function signIn(phone, password) {
  return dispatch => {
    dispatch(signInRequest());

    return services.fetchSignIn(phone, password)
      .then((config) => dispatch(signInSuccess(config)))
      .catch(err => dispatch(signInFailure(err)))
  };

  function signInRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_IN_REQUEST
          })
        })
    }
  }

  function signInSuccess({token, user}) {
    localStorage.setItem('token', token);

    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SIGN_IN_SUCCESS,
            token,
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            objects: user.objects,
            booking: user.booking
          })
        })
    }
  }

  function signInFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.SIGN_IN_FAILURE
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}

export function signOut() {
  return (dispatch, getState) => {
    localStorage.setItem('token', '');

    services.fetchSignOut(getState().user.token);

    return Promise.resolve()
      .then(() => {
        return dispatch({
          type: constants.SIGN_OUT
        })
      })
  };
}

/**
 * Получить данные пользователя
 * @param {string} token - уникальный ключ пользователя
 * @return {function} -
 */
export function getUserData(token) {
  return dispatch => {
    dispatch(getUserDataRequest());

    return services.fetchUserData(token)
      .then((user) => dispatch(getUserDataSuccess({token, user})))
      .catch(err => dispatch(getUserDataFailure(err)))
  };

  function getUserDataRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_USER_DATA_REQUEST
          })
        })
    }
  }

  function getUserDataSuccess({token, user}) {
    localStorage.setItem('token', token);

    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.GET_USER_DATA_SUCCESS,
            token,
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            objects: user.objects,
            booking: user.booking
          })
        })
    }
  }

  function getUserDataFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.GET_USER_DATA_FAILURE
          })
        })
        .then(() => {
          dispatch(signOut())
          throw err;
        })
    }
  }
}

export function subscribe(email) {
  return dispatch => {
    dispatch(subscribeRequest());

    return services.fetchSubscribe(email)
      .then(() => dispatch(subscribeSuccess()))
      .catch(err => dispatch(subscribeFailure(err)))
  };

  function subscribeRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SUBSCRIBE_REQUEST
          })
        })
    }
  }

  function subscribeSuccess() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.SUBSCRIBE_SUCCESS
          })
        })
    }
  }

  function subscribeFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.SUBSCRIBE_FAILURE
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}

/**
 * Получить данные пользователя
 * @param {string} token - уникальный ключ пользователя
 * @param {string} config - параметры профиля
 * @return {function} -
 */
export function updateProfile(token, config) {
  return dispatch => {
    dispatch(updateProfileRequest());

    return services.fetchUpdateProfile(token, config)
      .then(() => dispatch(updateProfileSuccess(token, config)))
      .catch(err => dispatch(updateProfileFailure(err)))
  };

  function updateProfileRequest() {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.UPDATE_USER_PROFILE_REQUEST
          })
        })
    }
  }

  function updateProfileSuccess(token, user) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          return dispatch({
            type: constants.UPDATE_USER_PROFILE_SUCCESS,
            name: user.name,
            email: user.email,
          })
        })
    }
  }

  function updateProfileFailure(err) {
    return dispatch => {
      return Promise.resolve()
        .then(() => {
          console.error('---- Error ----');
          console.error(err);
          console.error('---- Error ----');

          return dispatch({
            type: constants.UPDATE_USER_PROFILE_FAILURE
          })
        })
        .then(() => {
          throw err;
        })
    }
  }
}
