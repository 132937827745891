import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './FormAction.less';

class FormAction extends PureComponent {
  render() {
    const {className, style, children, size, inBlock} = this.props;
    const classes = classNames(
      styles['form-action'],
      size === 's' ? styles['form-action_size_s'] : null,
      size === 'm' ? styles['form-action_size_m'] : null,
      size === 'l' ? styles['form-action_size_l'] : null,
      size === 'fill' ? styles['form-action_size_fill'] : null,
      inBlock ? styles['form-action_in_block'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {children}
      </div>
    )
  }
}

FormAction.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Размер поля
   */
  size: PropTypes.oneOf(['s', 'm', 'l', 'fill']).isRequired,
  /**
   * Элемент находится в блоке (на мобилке дать отрицательный отступ)
   */
  inBlock: PropTypes.bool
};

export default FormAction;
