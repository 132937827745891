import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';

import classNames from 'classnames';
import styles     from './BlockField.less';

class BlockField extends PureComponent {
  render() {
    const {className, style, count, showInEnd, showInBot, children} = this.props;
    const classes = classNames(
      styles['block-field'],
      count === 'single' ? styles['block-field_count_single'] : null,
      count === 'double' ? styles['block-field_count_double'] : null,
      count === 'triple' ? styles['block-field_count_triple'] : null,
      count === 'two-thirds' ? styles['block-field_count_two-thirds'] : null,
      count === 'quarter' ? styles['block-field_count_quarter'] : null,
      count === 'three-quarters' ? styles['block-field_count_three-quarters'] : null,
      showInEnd ? styles['block-field_position_end'] : null,
      showInBot ? styles['block-field_position_bot'] : null,
      className
    );

    return (
      <div className={classes} style={style}>
        {children}
      </div>
    )
  }
}

BlockField.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object,
  /**
   * Элементы, которые отрисуются внутри svg-элемента, ожидается `ReactElement` или функция возвращающая его
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Размер блока
   */
  count: PropTypes.oneOf(['single', 'double', 'triple', 'two-thirds', 'quarter', 'three-quarters', 'auto']).isRequired,
  /**
   * Показывать блок в конце
   */
  showInEnd: PropTypes.bool,
  /**
   * Показывать блок снизу
   */
  showInBot: PropTypes.bool
};

export default BlockField
