exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".public-layout--aVZPd {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n@media (min-width: 960px) {\n  .public-layout--aVZPd {\n    padding: 30px 115px;\n  }\n}\n@media (min-width: 1280px) {\n  .public-layout--aVZPd {\n    padding: 40px 155px;\n  }\n}\n@media (min-width: 1600px) {\n  .public-layout--aVZPd {\n    padding: 50px 195px;\n  }\n}\n@media (min-width: 1920px) {\n  .public-layout--aVZPd {\n    padding: 60px 235px;\n  }\n}\n.public-layout_main--2mSAu {\n  padding: 0;\n}\n.public-layout__header--2IWDA,\n.public-layout__footer--1xbAa {\n  width: 100%;\n  -webkit-box-flex: 0;\n  -webkit-flex: 0 0 auto;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto;\n}\n.public-layout_main--2mSAu .public-layout__header--2IWDA,\n.public-layout_main--2mSAu .public-layout__footer--1xbAa {\n  display: none;\n}\n.public-layout__inner--2lX81 {\n  width: 100%;\n  -webkit-box-flex: 1;\n  -webkit-flex: 1 1 auto;\n      -ms-flex: 1 1 auto;\n          flex: 1 1 auto;\n}\n", ""]);

// exports
exports.locals = {
	"public-layout": "public-layout--aVZPd",
	"public-layout_main": "public-layout_main--2mSAu",
	"public-layout__header": "public-layout__header--2IWDA",
	"public-layout__footer": "public-layout__footer--1xbAa",
	"public-layout__inner": "public-layout__inner--2lX81"
};