import React, {PureComponent} from 'react';
import PropTypes              from 'prop-types';
import {hot}                  from 'react-hot-loader';

import {Helmet} from "react-helmet";

class Meta extends PureComponent {
  render() {
    const {title, keywords, description, og, twitter} = this.props;

    return (
      <Helmet>
        {title && <title>{title}</title>}
        {keywords && <meta name="keywords" content={keywords}/>}
        {description && <meta name="description" content={description}/>}

        {og && og.map((item) => {
          return (
            <meta key={item.property} property={'og:' + item.property} content={item.value}/>
          )
        })}

        {twitter && twitter.map((item) => {
          return (
            <meta key={item.property} name={'twitter:' + item.property} content={item.value}/>
          )
        })}
      </Helmet>
    )
  }
}

Meta.propTypes = {
  /**
   * Заголовок страницы
   */
  title: PropTypes.string.isRequired,
  /**
   * Ключевые слова страницы
   */
  keywords: PropTypes.string,
  /**
   * Описание страницы
   */
  description: PropTypes.string,
  /**
   * Open Graph разметка
   */
  og: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Название свойства
     */
    property: PropTypes.string,
    /**
     * Значение свойства
     */
    value: PropTypes.string
  })),
  /**
   * Twitter разметка
   */
  twitter: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Название свойства
     */
    property: PropTypes.string,
    /**
     * Значение свойства
     */
    value: PropTypes.string
  }))
};

export default hot(module)(Meta);
