import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";

import classNames from "classnames";
import styles from "./RatesPage.less";

import Helmet from "react-helmet";
import { Block, Link } from "Elements";
import { IconClose, IconCheck, IconArrowRight } from "Icons";
import document from "Assets/Бланк_голосования.pdf";
import { PHONE } from "../../utils/constants";
import { setScrollTop } from "../../utils";
import data from "./data";

class RatesPage extends PureComponent {
  componentDidMount() {
    setScrollTop(0);
  }
  render() {
    const { className, style } = this.props;
    const classes = classNames(styles["regulation-page"], className);

    return (
      <div className={classes} style={style}>
        <Helmet>
          <title>Квартира в ЖК «Ренессанс»</title>
        </Helmet>

        <h1>Наши тарифы</h1>

        <p>Информация о тарифах появится позже</p>
      </div>
    );
  }
}

RatesPage.propTypes = {
  /**
   * Дополнительный класс
   */
  className: PropTypes.string,
  /**
   * Дополнительные инлайновые стили
   */
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RatesPage)
);
